.tellafriend {
    &__description {
        &::after {
            content: "*";
            color: $color-saffron;
        }
    }

    &__remark {
        &::before {
            content: "*";
        }
    }
}
