@use "sass:math";

.modal-body {
    &__actions {

        & > button {
            display: block;
            margin-bottom: math.div($vertical-margin,  2);
        }
    }
}
