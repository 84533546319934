//buttons

.btn {
    &--full-width {
        width: 100%;
    }

    &--max-width {
        width: 100%;
        display: block;
        @media screen and (min-width: $screen-sm-min) {
            width: auto;
            margin-inline: auto;
            padding-inline: 2 * $gutter-width;
        }
    }

    &--innershadow {
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.3) !important;
        border: 0;
    }

    &--yellow {
        background-color: $color-honey;
        color: $color-darker;
    }

    &--gray {
        background-color: $color-light-gray;
        color: $color-darker;
    }

    &--gray-dark {
        background-color: $color-darker-gray;
        color: $color-white;
    }

    &--white {
        background-color: $color-white;
        color: $color-darker;
        &.btn--innershadow {
            box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3) !important;
        }
    }

    &--blue {
        background-color: $color-primary-blue;
        color: $color-white;

        &:hover {
            color: $color-white;
            background-color: darken($color-primary-blue, 10%);
        }
    }

    &--blue-dark {
        background-color: $color-dark-blue;
        color: $color-white;

        &:hover {
            color: $color-white;
            background-color: darken($color-dark-blue, 5%);
        }
    }

    &--blue-inverted {
        background-color: transparent;
        color: $color-primary-blue;
        border: $color-primary-blue solid 1px;

        &:hover {
            color: darken($color-primary-blue, 15%);
            border: darken($color-primary-blue, 15%) solid 1px;
        }
    }

    &--white-inverted {
        background-color: transparent;
        color: $color-white;
        border: $color-white solid 1px;

        &:hover {
            color: darken($color-white, 15%);
            border: darken($color-white, 15%) solid 1px;
        }
    }

    &--red {
        background-color: $color-saffron;
        color: $color-white;
    }

    &--bigger {
        line-height: $btn-big-height;
        padding-top: 0;
        padding-bottom: 0;
    }

    &--border {
        border: 1px solid $color-darker;
    }

    &--dark-text {
        color: $color-darker !important;

        &:hover {
            color: $color-darker !important;
        }
    }
}

.btn-primary {
    background-color: $color-primary-blue;
}

.unstyled-button {
    border: 0;
    padding: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    &:focus, &:hover {
        outline: 0;
        background-color: transparent !important;
    }

    &--honey {
        background-color: $color-honey !important;
        &:focus, &:hover {
            outline: 0;
            background-color: $color-honey !important;
        }
    }
    &--purple {
        background-color: $color-purple !important;
        &:focus, &:hover {
            outline: 0;
            background-color: $color-purple !important;
        }
    }
    &--saffron {
        background-color: $color-saffron !important;
        &:focus, &:hover {
            outline: 0;
            background-color: $color-saffron !important;
        }
    }
    &--caribbean {
        background-color: $color-caribbean !important;
        &:focus, &:hover {
            outline: 0;
            background-color: $color-caribbean !important;
        }
    }
    &--teal {
        background-color: $color-teal !important;
        &:focus, &:hover {
            outline: 0;
            background-color: $color-teal !important;
        }
    }
    &--rose {
        background-color: $color-rose !important;
        &:focus, &:hover {
            outline: 0;
            background-color: $color-rose !important;
        }
    }
    &--jade {
        background-color: $color-jade !important;
        &:focus, &:hover {
            outline: 0;
            background-color: $color-jade !important;
        }
    }
    &--orange {
        background-color: $color-orange !important;
        &:focus, &:hover {
            outline: 0;
            background-color: $color-orange !important;
        }
    }
}

// overwrites button-group style
.open > .dropdown-toggle.btn-default {
    background-color: transparent;
}
