.page {
    display: grid;

    grid-template-rows: max-content auto;

    min-height: 100vh;
    background-color: $color-white;

    @include desktop {
        height: auto;
        // min-height: 50vh;
    }

    &__content {
        width: 90vw;
        margin: $vertical-margin auto 0;
        @include desktop {
            width: 40vw;
        }
    }

    &__icon {
        width: 124px;
        margin: 0 auto;
    }
}

.page-content {
    &__lead {
        margin: 2*$vertical-margin 0 $vertical-margin;
    }

    &__content {
        flex: 1;
    }

    &__bottom {
        padding-bottom: $vertical-margin;
    }

    &--full-height {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .page-content--full-height  {
        display: block;
        margin-left: 30px;
        margin-right: 30px;
    }
 }
