.hc-alert {
    &__message {
        &--info {

        }

        &--fail {
            color: $color-saffron;
        }
    }
}
