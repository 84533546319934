.context-dropdown {

    height: auto;
    overflow-x: hidden;
    max-height: 80vh;
    float: none;
    padding: 0;

    &__item {
        padding: 0 0 0 $gutter-width;
        cursor: pointer;
    }

    @media screen and (min-width: $screen-md-min) {
        width: 100%;
        position: static;
        float: right;
        margin: inherit;
        padding: inherit;
        list-style: none;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        height: $header-height;
        line-height: $header-height;

        &--has-transparency {
            opacity: 0;
        }

        &.is-transparent {
            opacity: 1;
            animation: fadein 0.5s;
            @keyframes fadein {
                from { opacity: 0; }
                to   { opacity: 1; }
            }
        }

        &__item {
            display: inline-block;
            color: $color-white;
            padding-left: $gutter-width !important;
            height: inherit;
            line-height: inherit;
            border: none;
        }

        &--force {
            float: left;
            margin: pxToRem(2) 0 0 0;
            padding: pxToRem(5) 0;
            background-color: $color-white;
            border: solid 1px $color-light-gray;
            border-radius: pxToRem(4);
            box-shadow: 0 pxToRem(6) pxToRem(12) rgba(0, 0, 0, 0.175);
            height: auto;
            line-height: auto;

            .context-dropdown__item {
                padding-left: $gutter-width;
                height: 3*$gutter-width;
                line-height: 3*$gutter-width;
                display: list-item;
                color: $color-darker;
            }

            &.is-hidden {
                display: none !important;
            }
        }
    }
}
