@use "sass:math";

$font-size-h1: pxToRem(60);
$landing-padding: pxToRem(20);

$popular-radius: 20;
$popular-padding: 4;
$popular-top: math.div($popular-radius, 2) + $popular-padding;

.product-features {
    margin-top: 0;
} // remove when features class is not used on landing page


.border-none {
    border: none;
}

.features__header {

    @media screen and (min-width: $screen-sm-min) {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.products-header {

    &__subtitle {
        color: $color-white;
        font-size: $font-size-lead;
        margin: 0 0 $vertical-margin;
    }
}

.features {
    &__wrapper {

        margin: 0 $landing-padding;

        @media screen and (min-width: $screen-md-min) {
            max-width: $max-desktop-width;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &--sm-covered {
            margin: 0;
            @media screen and (min-width: $screen-sm-min) {
                margin: 0 $landing-padding;
            }
        }
        &--main {
            @media screen and (min-width: $screen-md-min) {
                margin-right: 0;
                margin-inline: auto;
            }
        }
    }
}

.feature-c2a {
    margin-top: $gutter-width;
}

.product-description {
    text-align: center;

    &__title, &__subtitle {
        font-size: $font-size-big;
        margin: 0 0 math.div($vertical-margin, 2);

        @media screen and (min-width: $screen-sm-min) {
            font-size: $font-size-h2 * 2;
        }

        @media screen and (min-width: $screen-lg-min) {
            font-size: $font-size-h1;
        }
    }

    @media screen and (min-width: $screen-md-min) {
        text-align: left;
    }

    &__text {
        font-size: pxToRem(18);
        line-height: 1.6;
    }
    &__italic{
        font-style: italic;
        color: $color-dark-blue;
    }

    &__link {
        color: $color-white;
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
        }
    }
}

.product-feature {

    &__title {
        padding-top: pxToRem(40);
        font-size: $font-size-h3 * 2;

        @media screen and (min-width: $screen-md-min){
            font-size: $font-size-h2 * 2;
            font-weight: bold;
        }
        @media screen and (min-width: $screen-lg-med) {
            font-size: $font-size-big * 2;
            font-weight: bold;
        }
    }

    &__subtitle {
        font-family: 'TheSans-B3Light', Helvatica, sans-serif;
        font-size: $font-size-h3 * 2;
        @media screen and (min-width: $screen-md-min){
            font-size: $font-size-h2 * 2 ;
            font-weight: 500;
        }
        @media screen and (min-width: $screen-lg-med) {
            font-size: $font-size-big * 2;
            font-weight: 500;
        }
    }

    &--img {
        color: #fff;
        background-repeat: no-repeat;
        min-height: 200px;
        background-size: cover;
        background-position: top left;

        @media screen and (min-width: $screen-sm-min) {
            min-height: pxToRem(375);
        }
        @media screen and (min-width: $screen-md-min){
            min-height: pxToRem(490);
        }
        @media screen and (min-width: $screen-lg-med) {
            min-height: pxToRem(750);
        }
    }
    &--img1 {
        background-image: url(../images/classroom5.jpg);
    }
    &--img2 {
        background-image: url(../images/classroom2.jpg);
    }
    &--img3 {
        background-image: url(../images/classroom3.jpg);
    }
    &--img4 {
        background-image: url(../images/classroom6.jpg);
    }
    &__info {
        padding-bottom: $vertical-margin;

        @media screen and (min-width: $screen-md-min) {
            padding-bottom: $vertical-margin * 3;
            padding-top: $vertical-margin * 3;
        }

        &--top {
            @media screen and (min-width: $screen-md-min) {
                padding-bottom: $vertical-margin * 2;
                padding-top: $vertical-margin * 2;
            }
        }
    }

    &-height {
        @media screen and (min-width: $screen-lg-med) {
            min-height: pxToRem(500);
        }
    }

    background-color: $color-white;

    &--blue {
        background-color: $color-primary-blue;
        color: $color-white;
    }

    &--green {
        background-color: $color-landing-green;
        color: $color-white;
    }

    &--almost-white {
        background-color: $color-almost-white;
    }

    &--dark-blue {
        background-color: $color-dark-blue;
        color: $color-white;
    }

    &--dark-gray {
        background-color: $color-lighter-gray;
    }

    &__description {
        padding-top: $vertical-margin;
        padding-bottom: pxToRem(10);
        @media screen and (min-width: $screen-md-min) {
            padding-top:0;
            width: 45%;
            &-wide{
                width: 70%;

            }
        }
        @media screen and (min-width: $screen-lg-med) {
            width: 47%;
            &-wide{
                width: 70%;

            }
        }

        &--sm-covered {
            padding: 0;
        }

    }

    &--reverse {
        & > div > div:first-of-type {
            order: 2
        }
        & > div > div:nth-of-type(2) {
            order: 1;
        }
    }

    &__image {

        & > img {
            display: block;
            max-width: 100%;
        }

        @media screen and (min-width: $screen-md-min) {
            max-width: 50%;
            & > img {
                vertical-align: inherit;
            }
        }

        @media screen and (min-width: $screen-lg-med) {
            max-width: 45%;
        }
    }
    &__video {
        position: relative;
        width: 100%;
        padding: pxToRem(40) 0;
        height: pxToRem(290);

        @media screen and (min-width: $screen-sm-min) {
            width: 85%;
            margin: 0 auto;
            height: pxToRem(430)
        }
        @media screen and (min-width: $screen-md-min) {
            width: 50%;
            margin: 0;
        }
        &-iframe {
            width: 100%;
            height: 100%;
            @media screen and (min-width: $screen-md-min) {
                height: 350px;
            }
        }
    }

    &__main-widget {
        background-color: $color-white;
        padding: 3*$gutter-width $gutter-width 1.5 * $gutter-width;
        color: $color-darker;
        text-align: left;

        @media screen and (min-width: $screen-sm-min) {
            padding-top: $gutter-width;
            max-width: pxToRem(380);
        }

        & > div {
            width: 100%;

            @media screen and (min-width: $screen-sm-min) {
                max-width: 500px;
                margin-left: pxToRem(60);
                padding-top: math.div($landing-padding, 2);
            }
        }

        &--less-margin {
            padding-top: $gutter-width;

            @media screen and (min-width: $screen-sm-min) {
                margin-top: $vertical-margin;
                padding-bottom: $gutter-width;
            }
        }
    }

    &--sm-covered {
        min-height: inherit;
    }
}

.feature-description {

    &--blue {
        color: $color-white;
    }

    &__title {
        margin: 0 0 math.div($vertical-margin, 2);
        font-size: $font-size-h3;
        font-weight: bolder;

        @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-h3 * 2;
        }

        @media screen and (min-width: $screen-lg-med) {
            font-size: pxToRem(48);
        }
    }

    &__title-color {
        color: $color-lighter-blue;
    }

    &__title1 {
        padding-top: 0;
    }

    &__subtitle {
        font-size: $font-size-h2;

        @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-big;
        }

        @media screen and (min-width: $screen-lg-med) {
            font-size: $font-size-big * 2;
        }
    }

    &__text {
        @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-lead;
        }
    }

    &__call2action {
        color: $color-primary-blue;
        & > a:hover {
            color: $color-white;
        }

        @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-lead;
        }
    }
}

.products {

    @media screen and (min-width: $screen-md-min) {
        width: $max-desktop-width;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.product {
    &--news {
        display: none;
        background-color: $color-white;
        padding:20px;
        -webkit-border-radius: 120px;
        -moz-border-radius: 120px;
        border-radius: 120px;
        position: absolute;
        width:200px;
        height: 200px;
        right: $vertical-margin * 2;
        color: $color-lighter-blue;
        text-align: center;
        transform: rotate(20deg);
        -webkit-box-shadow: 1px 2px 8px  $color-black;
        -moz-box-shadow: 1px 2px 8px  $color-black;
        box-shadow: 1px 2px 8px  $color-black;

        @media screen and (min-width: $screen-sm-min) {
            top : $vertical-margin * 9;
            display: block;
        }

        @media screen and (min-width: $screen-md-min) {
            top : $vertical-margin * 13;
        }

        @media screen and (min-width: $screen-lg-med) {
            top: $vertical-margin * 22;
        }

        &__title {
            padding: 30px 30px 0 30px;
            margin: 0;
            font-weight: 600;
        }

        &__text {
            margin-top: 10px;
            padding: 10px 20px 30px 20px;
            margin: 0;
            font-weight: 600;
            margin-top: 30px;
            font-size: 2rem;
        }
    }
    margin: 2*$vertical-margin $gutter-width;
    padding: 0 $gutter-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $screen-md-min) {
        width: 49%;
        margin: 2*$vertical-margin 0;
        padding: 0;
    }

    &--position {

        width: 100%;

        @media screen and (min-width: $screen-sm-min) {
            padding-top: $vertical-margin * 2;
            padding-bottom: pxToRem(120);
        }
        @media screen and (min-width: $screen-md-min) {
            padding-top: $vertical-margin * 2;
        }
        @media screen and (min-width: $screen-lg-med) {
            padding-top: $vertical-margin * 2;
        }
    }
    &__call2action {
        margin: 0;
        padding-bottom: $vertical-margin;
        text-align: center;
        border-bottom: solid 1px $color-outline-gray;

        @media screen and (min-width: $screen-md-min) {
            border:none;
        }
        @media screen and (min-width: $screen-lg-med) {
        text-align: left;
        }
        &--last-child {
            border-bottom: none;
        }

    }

    &--popular {
        border: 3px solid $color-primary-blue;
        position: relative;
    }

    &__banner {
        border-radius: pxToRem($popular-radius);
        display: inline-block;
        position: absolute;
        color: $color-white;
        background-color: $color-primary-blue;
        left: 50%;
        transform: translateX(-50%);
        line-height: pxToRem($popular-radius);
        top: pxToRem(-$popular-top);
        padding: pxToRem($popular-padding);

        @media screen and (min-width: $screen-md-min) and (max-width: $screen-lg-med) {
            left: 10%;
            right: 10%;
            transform: translateX(-0%);
        }

        & > p {
            text-align: center;
            margin: 0 pxToRem(10);
        }
    }
    &-section-list {
        text-align: center;
        padding-bottom: pxToRem(50);
        &__icon{
            background-repeat: no-repeat;
            background-size: contain;
            width: pxToRem(80);
            height: pxToRem(80);
            margin: 0 auto;
        }
    }

    &--icon-img1 {
        background-image: url(../images/book_LIGHT.svg);
    }
    &--icon-img2 {
        background-image: url(../images/crowd_LIGHT.svg);
    }
    &--icon-img3 {
        background-image: url(../images/mobile-laptop_LIGHT.svg);
    }
    &--icon-img4 {
        background-image: url(../images/smartphone_LIGHT.svg);
    }

    &--90width {
        @media screen and (min-width: $screen-sm-min) {
            max-width: 90vw;
        }
    }
}

.product-header {
    text-align: center;
    padding-bottom: math.div($vertical-margin, 2);
    margin: 0;

    @media screen and (min-width: $screen-lg-med) {
        text-align: left;
    }

    &__text {
        font-size: pxToRem(18);
        @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-lead;
        }
    }

    &__title {
        margin-bottom: pxToRem(16);
        font-size: $font-size-h2;

        @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-big;
        }

        @media screen and (min-width: $screen-lg-med) {
            font-size: $font-size-big;
            min-width:pxToRem(330);
        }
    }

    &__price {
        color: $color-primary-blue;
        margin-bottom: 0;
    }// add to product-footer
}

.product-footer {
    text-align: center;
    &__price {
        color: $color-primary-blue;
        margin-bottom: $vertical-margin;
    }
    &__text {
        font-size: $font-size-lead;
    }
}

.product-feature-list {
    padding-top: $vertical-margin;

    &__item {
        margin-bottom: $vertical-margin;
        display: flex;
        text-align: left;

        &:before {
            content: url('../icons/check.svg');
            margin-right: 2*$gutter-width;
            position: relative;
            top: pxToRem(2);
        }
    }
}

// new products

.product--list {
    padding: 0;
    margin: 0;
    margin-top: pxToRem(40);
    list-style: none;


    @media screen and (min-width: $screen-sm-min) {
        padding: $vertical-margin * 2 0;
        width: $max-desktop-width;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }


    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        @media screen and (min-width: $screen-sm-min) {
            width: 45%;
            img {
                width: 100%;
            }
        }

        &--center {
            @media screen and (min-width: $screen-sm-min) {
                margin: 0 auto;
            }
        }
    }
}

.privacy {
    &--icon-img1 {
        background-image: url(../icons/swiss_2_LIGHT.svg);
    }

    &--icon-img2 {
        background-image: url(../icons/privacy_LIGHT.svg);
    }

    &--icon-img3 {
        background-image: url(../icons/callfilter_LIGHT.svg);
    }
}


// todo: move to buttons.scss

.btn--massive {
    line-height: $btn-massive-height;
    padding: 0 2*$gutter-width;
}

.video-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-black-transparent;

    &__wrapper {
        padding: 30% 2*$gutter-width;

        @media screen and (min-width: $screen-sm-min) {
            padding: 5% 10%;
        }
    }

    &__video {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0;

        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

// login actions in header
.landing-loginactions {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-bottom: $vertical-margin;

    & > * {
        color: $color-white;
        margin-bottom: math.div($gutter-width, 3);
        width: 100%;

        &:first-child {
            margin-bottom: $gutter-width;
            @media screen and (min-width: $screen-sm-min) {
                margin-right: $gutter-width;
                margin-bottom: math.div($gutter-width, 3);
            }
        }
    }

    @media screen and (min-width: $screen-sm-min) {
        flex-direction: row;
        align-items: center;
        padding: 0;
    }
}

// helloclass heading
.product-title {
    display: none;
    text-align: left;

    @media screen and (min-width: $screen-sm-min) {
        margin-left: pxToRem(60);
        display: flex;
        flex-direction: column;
        margin-bottom: 4 * $gutter-width;
    }

    @media screen and (min-width: $screen-lg-min) {
        flex-direction: row;
    }

    &__heading {
        margin: 0;

        &:first-child {
            @media screen and (min-width: $screen-lg-min) {
                &::after {
                    margin: 0 $gutter-width;
                    content: "\2010"
                }
            }
        }
    }
}

.mobile-loginactions {
    margin: 0
}
