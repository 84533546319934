.payment-promotion {
    &__title {
        font-family: 'TheSans-B6SemiBold'
    }
}

.promotion-form {
    display: flex;
    justify-content: space-between;

    &__input {
        width: 60%;

        & input {
            margin-top: 0 !important;
            color: $color-primary-blue !important;
        }
    }

    &__submit {
        width: 35%;
        height: pxToRem(48);
    }
}

.promotion-text {

    display: flex;
    justify-content: space-between;

    &__title {
        width: 30%;
        &::after {
            content: ":";
        }
    }

    &__text {
        width: 60%;
        text-align: right;
        font-family: 'TheSans-B6SemiBold';
    }
}
