@tailwind base;
@tailwind components;
@tailwind utilities;

@import "bootstrap";
@import 'ngx-toastr/toastr';
@import "tools.functions";
@import "variables";
@import "mediaqueries";
@import "colors";
@import "mixins";
@import "typography";
@import "icons";
@import "generic";
@import "sdx";
@import "buttons";

@import "components/components";
@import "header";
@import "app";
@import "page";
@import "pages";
@import "lead-in";
@import "toast";
@import "shame";


