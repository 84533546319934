.widget {

    border-radius: $widget-radius;

    box-shadow: 0 5px 12px 0 rgba(0,0,0,0.15);
    margin-top: $vertical-margin;

    &__header {
        padding: 0 $gutter-width;
        background-color: $color-honey;
        border-top-left-radius: pxToRem(12);
        border-top-right-radius: pxToRem(12);
        border: solid 1px darken($color-honey, 10%);

        & > * {
            padding-bottom: pxToRem(5);
        }
    }

    &__body {
        background-color: $color-white;
        border-bottom-left-radius: pxToRem(12);
        border-bottom-right-radius: pxToRem(12);
        border: solid 1px $color-light-gray;
        border-top: 0;
        padding: $gutter-width;
    }
}
