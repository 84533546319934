.action-panel {
    position: fixed;
    height: $admin-list-height-mobile;
    background-color: $color-light-gray;
    font-size: $font-size-text;

    bottom: -$admin-list-height-mobile;
    left: 0;
    right: 0;

    transition: .25s ease-in-out;

    @media screen and (min-width: $screen-md-min) {
      height: $admin-list-height;
      bottom: -$admin-list-height;
    }

    &--big {
        height: $admin-list-height-big-mobile;
        bottom: -$admin-list-height-big-mobile;

        @media screen and (min-width: $screen-md-min) {
            height: $admin-list-height;
            bottom: -$admin-list-height;
          }
    }

    &--small {
        height: $admin-list-height;
        bottom: -$admin-list-height;

        .action-panel__selection {
            height: $admin-list-height;
            line-height: $admin-list-height;
            width: 20px;
            margin-top: pxToRem(5);
        }
    }

    &.is-shown {
        bottom: 0;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__actions {

        list-style: none;
        padding: $gutter-width 0 0;

        @media screen and (min-width: $screen-md-min) {
            padding: 0;
            height: pxToRem(60);
            line-height: pxToRem(60);
        }
    }

    &-actions {
      &__item {
        display: block;
        padding-bottom: $gutter-width;
        height: pxToRem(35);
        line-height: pxToRem(35);

        @media screen and (min-width: $screen-md-min) {
          display: inline-block;
          margin-right: 2*$gutter-width;
        }
      }
    }

    &__icon {
      padding-right: pxToRem(9);
      font-size: pxToRem(20);
      position: relative;
      top: pxToRem(4);
    }

    &__selection {
        height: $admin-list-height-mobile;
        line-height: $admin-list-height-mobile;
        width: 20px;
        margin-top: $gutter-width;

        @media screen and (min-width: $screen-md-min) {
          height: $admin-list-height;
          line-height: $admin-list-height;
          padding-right: $gutter-width;
          margin: 0;
        }
    }
}
