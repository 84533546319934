.readonly-box {
    height: pxToRem(48);
    line-height: pxToRem(48);
    font-size: $font-size-h3;
    border: $color-text-gray 1px solid;
    border-radius: $small-radius;
    background-color: $color-lighter-gray;
    color: $color-text-gray;
    padding: 0 pxToRem(18);
}
