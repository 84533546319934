@use "sass:math";

.absence-users {

    margin-top: $header-height;

    &__main {
        margin-top: math.div($vertical-margin, 2);
        @media screen and (min-width: $screen-md-min) {
            margin-top: 2.5 * $vertical-margin;
        }
    }

    &__date-wrapper {
        display: block;
        margin: 0 (-$gutter-width) 0 (-$gutter-width);
        padding: 0 $gutter-width;
        background-color: $color-lighter-gray;
        @include grey-shadow;

        @media screen and (min-width: $screen-md-min) {
            top: $header-height !important;
        }

    }

    &__dateselection {
        display: block;
        padding: 0;

        @media screen and (min-width: $screen-md-min) {
            padding: 0 $gutter-width;
        }
    }

    &__actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: math.div($vertical-margin, 2)
    }

    &__list {

        &--no-topmargin {
            margin-top: 0;

            @media screen and (min-width: $screen-md-min) {
                margin-top: 2*$vertical-margin;
            }
        }
    }

    &__add-absence {
        color: $color-black;

        &:hover {
            color: $color-black;
        }
    }
}

.user-absence {
    &__summary-list {
        list-style: none;
        padding-left: 0;
        color: $color-text-gray;
        font-size: $font-size-small;
        line-height: $font-size-h3;
    }
}
