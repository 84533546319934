.features {
    margin-top: $vertical-margin;
}

.feature {

    margin-top: $vertical-margin;

    @media screen and (min-width: $screen-md-min) {
        &__image-wrapper--right {
            float: right;
        }
    }

    &__info {
        margin-top: $vertical-margin;

        @media screen and (min-width: $screen-sm-min) {
            margin-top: 0;
        }
    }

    &__image {
        background-color: $color-light-gray;
        width: 90%;
        display: block;
        margin: 0 auto;
        max-width: pxToRem(400);

        @media screen and (min-width: $screen-md-min) {
            width: 100%;
        }
    }
}