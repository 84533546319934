@use "sass:math";

.activation-display {
    padding: 0 pxToRem(15) 0 0;
    font-size: $font-size-h3;

    &__instruction {
        font-size: $font-size-h3;
    }

    &__data-section {
        font-size: $font-size-h3;

        & svg {
            height: pxToRem(128) !important;
            width: pxToRem(128) !important;
        }
    }

    &__list-item {
        margin-top: math.div($vertical-margin, 2);
    }

}
