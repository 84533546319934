.footer-wrapper {
    // margin-top: $vertical-margin;
    padding: 0 $gutter-width;
    background-color: $color-lighter-gray;
}

.footer {

    min-height: pxToRem(150);
    padding-top: $vertical-margin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;

    @media screen and (min-width: $screen-md-min) {
        max-width: $max-desktop-width;
        flex-direction: row;
    }

    &__section {
        width: 100%;
        @media screen and (min-width: $screen-md-min) {
            width: 30%;
        }
    }

    &__support, &__legal {
        a {
            color: $color-text-gray;
        }
    }
}

.footer-list {

    margin-top: $vertical-margin;

    @media screen and (min-width: $screen-md-min) {
        margin-top: inherit;
    }

    &__item {

        margin-top: $gutter-width;

        @media screen and (min-width: $screen-md-min) {
            margin-top: inherit;
        }
    }
}
