.edulog {
    background-color: rgba(0, 38, 84, 0.06);
    padding: $vertical-margin $gutter-width;
    text-align: center;
    margin-bottom: $vertical-margin;

    &__logo {
        height: pxToRem(36);
        margin-bottom: pxToRem(24)
    }

    &__text {
        font-size: $font-size-form;
        margin-bottom: pxToRem(20);

        & > p {
            margin-bottom: 0;
        }
    }

    &__button {
        width: 100%;
    }
}
