.login-widget {

    &__title {
        text-align: left;
        margin-bottom: $gutter-width;

        @media screen and (min-width: $screen-sm-min) {
            font-size: $font-size-h2;
            margin-bottom: $gutter-width;
        }
    }

    &__actions {
        //border-bottom: 1px solid $color-light-gray;
        padding-bottom: 1.5 * $gutter-width;
        //margin-bottom: 1.5 * $gutter-width;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $screen-sm-min) {
            flex-direction: row;
        }

        button {
            display: block;
            margin-bottom: $gutter-width;

            @media screen and (min-width: $screen-sm-min) {
                margin-bottom: 0;
            }

            &:last-of-type {
                margin-bottom: 0;

                @media screen and (min-width: $screen-sm-min) {
                    margin-left: $gutter-width;
                }
            }
        }
    }
}
