.semester-dates {
    display: flex;
    flex-direction: row;
    margin-top: pxToRem(16);

    &__date:first-of-type {
        margin-right: 3*$gutter-width;
    }
}

.form-semesters {
    margin-top: $vertical-margin;
}

.school-form {
    &__name {
        margin-bottom: pxToRem(38);
    }
}

.border-bottom {
    border-bottom: solid 1px $color-outline-gray;
    padding-bottom: pxToRem(10);
    margin-bottom: pxToRem(16);
}

