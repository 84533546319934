.absenceform {
    margin-top: $vertical-margin;
}

.absence-error {
    margin-bottom: $vertical-margin;
}

.absenceform {
    &__remark {
        min-height: pxToRem(180);
        font-size: $font-size-form;
    }

    &__time {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.absence-time {

    @media screen and (min-width: $screen-xs-min) {
        width: 50%;
    }

    &__select {
        margin-bottom: 0;
    }

    &__text {
        font-size: $font-size-form;
        margin-bottom: 0;
    }

    & table {
        margin: 0 auto;
    }

    & input {
        font-size: $font-size-form;
    }
}
