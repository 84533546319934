.simple-header {

    display: grid;
    grid-template-columns: pxToRem(50) 1fr pxToRem(50);
    grid-column-gap: pxToRem(5);
    justify-items: center;
    height: $new-heading-height;
    grid-template-areas: "back heading menu";
    margin-bottom: $gutter-width;

    .main-title {
        grid-area: heading;

        &__heading {
            margin-top: 0;
            margin-bottom: 0;
            height: $new-heading-height;
            text-indent: inherit;
        }
    }

    &__back {
        margin: $header-title-margin 0 $header-title-margin;
        height: $new-heading-title-height;
        grid-area: back;
        margin-right: 0;
        float: none;
        line-height: $new-heading-title-height !important;
        &--isHidden {
            display: none !important;
        }
    }
}
