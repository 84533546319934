.loader-spinner {

    height: calc(100vh - 5rem);
    padding-top: 5rem;

    &.isHidden {
        display: none;
    }
}




