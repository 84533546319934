@use "sass:math";

.relationship-manage {

    padding-bottom: math.div($vertical-margin, 2);

    &__add {
        color: $color-primary-blue;
        cursor: pointer;
        font-size: $font-size-text;
    }
}

.relationshipmanage-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: math.div($vertical-margin, 2);

    &__content {
        width: 100%;
    }

    &__delete {
        width: pxToRem(40);
        text-align: right;
    }
}


// srelationship.selection.component.ts
.relationship-selection {
    &__select {
        z-index: auto;
    }
}
