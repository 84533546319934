// colors
$color-white: rgba(255, 255, 255, 1);
$color-white-transparent: rgba(255, 255, 255, 0.3);
$color-almost-white: rgb(250, 250, 250);
$color-black: rgba(0, 0, 0, 1);
$color-black-transparent: rgba(0, 0, 0, 0.2);
$color-dark-blue: rgba(0, 17, 85, 1);           // navy
$color-blue-sky: rgba(134, 220, 241, 1);       // sky
// $color-primary-blue: rgba(17, 170, 255, 1);
$color-primary-blue: rgb(23, 129, 227);
$color-light-blue: rgba(222, 244, 255, 1);
$color-almost-white: rgba(250, 250, 250, 1);

$color-saffron: rgba(192, 34, 49, 1);
$color-caribbean: rgba(22, 190, 152, 1);
$color-honey: rgba(255, 176, 0, 1);
$color-teal: rgba(6, 134, 147, 1);
$color-rose: rgba(237, 74, 107, 1);
$color-jade: rgba(9, 120, 63, 1);
$color-orange: rgba(255, 125, 42, 1);
$color-purple: rgba(132, 60, 190, 1);
$color-wall-yellow: rgba(246, 245, 233, 1);
$color-form-error: #d12;
$color-text-warning: #FF8B2E;

$color-info: #ADE0F8;
$color-warning: #FFE487;
$color-alert: #FFA087;
$color-admin-message: rgba(0,0,0,0.5);

$colors: (
    saffron: $color-saffron,
    caribbean: $color-caribbean,
    honey: $color-honey,
    teal: $color-teal,
    rose: $color-rose,
    jade: $color-jade,
    orange: $color-orange,
    purple: $color-purple,
    wall-yellow: $color-wall-yellow,
);


$color-blue: rgba(62, 99, 194, 1);
$color-lighter-blue: rgba(47, 59, 118, 1);
$color-greyish-blue: rgba(129, 138, 171, 1);

$color-darker-gray: rgba(134, 134, 134, 1);
$color-text-gray: rgba(161, 161, 161, 1);
$color-outline-gray: rgb(184, 184, 184);
$color-lighter-gray: #F5F5F5;
$color-light-gray: #d7d7d7;
$color-almost-white: rgb(250, 250, 250);
$color-darker: rgba(51, 51, 51, 1);
$color-landing-green: rgb(37, 178, 82);

$color-input-border: $color-light-gray;

