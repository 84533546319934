.call2action {
    background-image: url('../../icons/school.svg');
    background-repeat: no-repeat;
    background-size: pxToRem(150);
    background-position: 90% 108%;

    &__link {
        margin-bottom: 3*$vertical-margin;
    }
}
