@use "sass:math";

.calendar {
    background-color: $color-lighter-gray;
    margin: 0 -15px;
    padding: 0 15px;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);;

    @media screen and (min-width: $screen-md-min) {
        display: flex;
    }

    &__toprow {
        order: 2;
        position: relative;
        padding-top: 1rem;
    }

    .toprow__left {
        float: left;
        width: 45%;

        @media screen and (min-width: $screen-md-min) {
            display: inline-block;
            float: none;
            width: auto;
            margin: 0 $gutter-width;
        }
    }

    .toprow__right {
        float: right;
        width: 45%;
        text-align: right;

        @media screen and (min-width: $screen-md-min) {
            display: inline-block;
            float: none;
            text-align: left;
            width: auto;
            margin: 0 $gutter-width;
        }
    }

    &__selection {
        display: block;

        &.is-hidden {
            display: none;
        }

        @media screen and (min-width: $screen-md-min) {
            position: absolute;
            top: 3rem;
            left: $gutter-width;
            right: $gutter-width;
            background-color: $color-white;
            z-index: 50;
            width: auto !important;
            min-width: 300px !important;
        }
    }

    &__weekdays {
        clear: both;
        order: 1;
        padding: 1rem 0;
        border-top: 1px solid $color-light-gray;
        @media screen and (min-width: $screen-md-min) {
            border: 0;
        }
    }

    .is-selected {
        background-color: $color-primary-blue;
        border-radius: 3px;
        color: $color-white;
    }
}

.calendar-selection {

    border: 1px solid $color-light-gray;

    @media screen and (min-width: $screen-md-min) {
        margin: 0;
        width: auto;
    }

    &__year {
        padding: 1.5rem 0 1.5rem 1.5rem;
    }

    &__month {
        border-top: 1px solid $color-light-gray;
        padding: 1rem;
    }

    &__text {
        display: inline;
        padding: 0 $gutter-width;
    }

}

.month {
    &__col {
        display: inline-block;
        width: 30%;
        margin: $gutter-width 0;
    }
}

.day-selection {
    display: flex;
    justify-content: space-between;

    &__weekday {
        display: inline-block;

        @media screen and (min-width: $screen-md-min) {
            display: none;
        }

        @media screen and (min-width: $screen-md-min) {
            &:first-of-type, &:last-of-type {
                display: inline-block;
            };

            &:first-of-type:after {
                content: "\2013";
            }
        }

        &.is-selected {
            @media screen and (min-width: $screen-md-min) {
                background-color: transparent;
                color: $color-darker;
            }
        }
    }
}

.weekday-button {
    padding: math.div($gutter-width, 3) $gutter-width;
    @media screen and (min-width: $screen-md-min) {
        padding: 0 $gutter-width;
    }
}

.selection-toggle {

    &--form {
        font-size: $font-size-form;
    }
    .icon-arrow-down {
        transition: .25s ease-in-out;
        transform-origin: 50% 50%;
        margin-left: pxToRem(10);
        display: inline-block;
    }

    &.is-active .icon-arrow-down {
        transform: rotate(180deg);
    }

}

.calendar-button {
    border: 0;
    background-color: transparent;
    padding: pxToRem(5) pxToRem(10);
}
