.login-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__container {
        width: 100%;
    }
}

.login-footer {
    width: 100%;
    margin-top: auto;
}
