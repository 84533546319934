.payment-result-page {
    &__content {
        text-align: center;
        display: grid;
        grid-template-rows: auto auto;

        svg {
            align-self: end;
        }

        .lead-in {
            align-self: start;
        }

    }

    &__heading {
        text-align: center;
    }

    &__subheading {
        text-align: center;
    }


    &__page-action {
        display: grid;
        align-content: center;
        justify-content: center;
        grid-row-gap: pxToRem(10);
    }
}
