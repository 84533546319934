.infopoint {
    display: flex;
    flex-direction: column;
    line-height: 24px;
    border-bottom: 1px solid $color-light-gray;
    padding-block: pxToRem(10);

    @media screen and (min-width: $screen-md-min) {
        padding-top: pxToRem(5);
        flex-direction: row;
        margin-bottom: pxToRem(5);
    }

    & > * {
        margin: 0;
        line-height: 24px;
    }

    &__title {
        @media screen and (min-width: $screen-md-min) {
            margin-right: pxToRem(10);
        }
    }

    &__text {
        font-size: $font-size-heading3;
        @media screen and (min-width: $screen-md-min) {
            margin-right: pxToRem(10);
        }
    }

    &__link {
        font-size: $font-size-heading3
    }
}
