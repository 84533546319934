.sidebar {
    z-index: 20;
    background-color: $color-white;
    position: fixed;
    top: $header-height;
    bottom: 0;
    width: 90%;
    right: -90%;
    transition: .25s ease-in-out;

    &--is-shown {
        right: 0;
    }

    &__message {
        padding-left: $gutter-width;
    }

    @media screen and (min-width: $screen-md-min) {
        width: $sidebar-width;
        right: -$sidebar-width;

        &--is-shown {
            right: 0;
            box-shadow: -5px 2px 5px 0px $color-lighter-gray;
        }
    }
}
