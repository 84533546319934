@font-face {
    font-family: 'sdx-icons';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/sdx-icons/sdx-icons.eot') format('eot'),
    url('../fonts/sdx-icons/sdx-icons.woff2') format('woff2'),
    url('../fonts/sdx-icons/sdx-icons.woff') format('woff'),
    url('../fonts/sdx-icons/sdx-icons.ttf') format('truetype'),
    url('../fonts/sdx-icons/sdx-icons.svg#sdx-icons') format('svg');
  }

  .sdx-icon {
    display: inline-block;

    text-transform: none;
    text-decoration: none;
    text-rendering: auto;

    line-height: 1;

    font-family: 'sdx-icons' !important;
    font-size: inherit;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-stretch: normal;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Icon size styles
    // -------------------------

    &--s1 {
      font-size: 16px;
    }

    &--s2 {
      font-size: 24px;
    }

    &--s3 {
      font-size: 32px;
    }

    &--s4 {
      font-size: 40px;
    }

    &--s5 {
      font-size: 48px;
    }

    &--s6 {
      font-size: 56px;
    }

    // Flip Icon
    // -------------------------

    &--flip-horizontal {
      transform: scale(-1, 1);
    }

    &--flip-vertical {
      transform: scale(1, -1);
    }

    &--flip-horizontal-vertical {
      transform: scale(-1, -1);
    }


  }

.icon-026-exclamation-mark-circle::before {
    content: "\e01a";
  }


.input-field {
    position: relative;
    margin-bottom: 11px;
    padding: 0;
  }

  .input-field .message {
    // max-height: 0;
    // margin-top: 0;
    // opacity: 0;
    // z-index: 11;
    // transition: 150ms all cubic-bezier(0.4, 0, 0.2, 1);
    max-height: 50px;
    margin-top: 6px;
    opacity: 1;
    color: #d12;
  }

  .input-field .icon {
    margin-right: 4px;
  }

  .input-field:first-child input:not([type]),
  .input-field:first-child input[type='text'],
  .input-field:first-child input[type='password'],
  .input-field:first-child input[type='email'],
  .input-field:first-child input[type='url'],
  .input-field:first-child input[type='time'],
  .input-field:first-child input[type='date'],
  .input-field:first-child input[type='datetime'],
  .input-field:first-child input[type='datetime-local'],
  .input-field:first-child input[type='tel'],
  .input-field:first-child input[type='number'],
  .input-field:first-child input[type='search'],
  .input-field:first-child textarea {
    margin-top: 0;
  }

  .input-field input,
  .input-field textarea {
    -webkit-border-radius: 0;
  }

  .input-field input::-ms-clear,
  .input-field textarea::-ms-clear {
    display: none;
  }

  .input-field input:not([type]),
  .input-field input[type='text'],
  .input-field input[type='password'],
  .input-field input[type='email'],
  .input-field input[type='url'],
  .input-field input[type='time'],
  .input-field input[type='date'],
  .input-field input[type='datetime'],
  .input-field input[type='datetime-local'],
  .input-field input[type='tel'],
  .input-field input[type='number'],
  .input-field input[type='search'],
  .input-field textarea {
    line-height: 24px;
    letter-spacing: -.1px;
    font-size: 18px;
    position: relative;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 10;
    margin-top: 13px;
    outline: none;
    border: 1px solid $color-input-border;
    border-radius: 5px;
    background-color: #fff;
    padding: $input-padding;
    width: 100%;
    height: 48px;
    color: #333;
    font-weight: 300;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .input-field input:not([type]) + label,
  .input-field input[type='text'] + label,
  .input-field input[type='password'] + label,
  .input-field input[type='email'] + label,
  .input-field input[type='url'] + label,
  .input-field input[type='time'] + label,
  .input-field input[type='date'] + label,
  .input-field input[type='datetime'] + label,
  .input-field input[type='datetime-local'] + label,
  .input-field input[type='tel'] + label,
  .input-field input[type='number'] + label,
  .input-field input[type='search'] + label,
  .input-field textarea + label {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateY(24px);
    transform: translateY(24px);
    transition: 150ms all cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    cursor: text;
    color: #666;
    font-size: 14px;
  }

  .input-field input:not([type]) ~ .icon,
  .input-field input[type='text'] ~ .icon,
  .input-field input[type='password'] ~ .icon,
  .input-field input[type='email'] ~ .icon,
  .input-field input[type='url'] ~ .icon,
  .input-field input[type='time'] ~ .icon,
  .input-field input[type='date'] ~ .icon,
  .input-field input[type='datetime'] ~ .icon,
  .input-field input[type='datetime-local'] ~ .icon,
  .input-field input[type='tel'] ~ .icon,
  .input-field input[type='number'] ~ .icon,
  .input-field input[type='search'] ~ .icon,
  .input-field textarea ~ .icon {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 101;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #25b252;
    font-size: 22px;
  }

  .input-field input:not([type])::-webkit-input-placeholder,
  .input-field input[type='text']::-webkit-input-placeholder,
  .input-field input[type='password']::-webkit-input-placeholder,
  .input-field input[type='email']::-webkit-input-placeholder,
  .input-field input[type='url']::-webkit-input-placeholder,
  .input-field input[type='time']::-webkit-input-placeholder,
  .input-field input[type='date']::-webkit-input-placeholder,
  .input-field input[type='datetime']::-webkit-input-placeholder,
  .input-field input[type='datetime-local']::-webkit-input-placeholder,
  .input-field input[type='tel']::-webkit-input-placeholder,
  .input-field input[type='number']::-webkit-input-placeholder,
  .input-field input[type='search']::-webkit-input-placeholder,
  .input-field textarea::-webkit-input-placeholder {
    transition: 150ms opacity cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    color: #bbb;
  }

  .input-field input:not([type])::-moz-placeholder,
  .input-field input[type='text']::-moz-placeholder,
  .input-field input[type='password']::-moz-placeholder,
  .input-field input[type='email']::-moz-placeholder,
  .input-field input[type='url']::-moz-placeholder,
  .input-field input[type='time']::-moz-placeholder,
  .input-field input[type='date']::-moz-placeholder,
  .input-field input[type='datetime']::-moz-placeholder,
  .input-field input[type='datetime-local']::-moz-placeholder,
  .input-field input[type='tel']::-moz-placeholder,
  .input-field input[type='number']::-moz-placeholder,
  .input-field input[type='search']::-moz-placeholder,
  .input-field textarea::-moz-placeholder {
    transition: 150ms opacity cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    color: #bbb;
  }

  .input-field input:not([type]):-ms-input-placeholder,
  .input-field input[type='text']:-ms-input-placeholder,
  .input-field input[type='password']:-ms-input-placeholder,
  .input-field input[type='email']:-ms-input-placeholder,
  .input-field input[type='url']:-ms-input-placeholder,
  .input-field input[type='time']:-ms-input-placeholder,
  .input-field input[type='date']:-ms-input-placeholder,
  .input-field input[type='datetime']:-ms-input-placeholder,
  .input-field input[type='datetime-local']:-ms-input-placeholder,
  .input-field input[type='tel']:-ms-input-placeholder,
  .input-field input[type='number']:-ms-input-placeholder,
  .input-field input[type='search']:-ms-input-placeholder,
  .input-field textarea:-ms-input-placeholder {
    transition: 150ms opacity cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    color: #bbb;
  }

  .input-field input:not([type])::-ms-input-placeholder,
  .input-field input[type='text']::-ms-input-placeholder,
  .input-field input[type='password']::-ms-input-placeholder,
  .input-field input[type='email']::-ms-input-placeholder,
  .input-field input[type='url']::-ms-input-placeholder,
  .input-field input[type='time']::-ms-input-placeholder,
  .input-field input[type='date']::-ms-input-placeholder,
  .input-field input[type='datetime']::-ms-input-placeholder,
  .input-field input[type='datetime-local']::-ms-input-placeholder,
  .input-field input[type='tel']::-ms-input-placeholder,
  .input-field input[type='number']::-ms-input-placeholder,
  .input-field input[type='search']::-ms-input-placeholder,
  .input-field textarea::-ms-input-placeholder {
    transition: 150ms opacity cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    color: #bbb;
  }

  .input-field input:not([type])::placeholder,
  .input-field input[type='text']::placeholder,
  .input-field input[type='password']::placeholder,
  .input-field input[type='email']::placeholder,
  .input-field input[type='url']::placeholder,
  .input-field input[type='time']::placeholder,
  .input-field input[type='date']::placeholder,
  .input-field input[type='datetime']::placeholder,
  .input-field input[type='datetime-local']::placeholder,
  .input-field input[type='tel']::placeholder,
  .input-field input[type='number']::placeholder,
  .input-field input[type='search']::placeholder,
  .input-field textarea::placeholder {
    transition: 150ms opacity cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    color: #bbb;
  }

  .input-field input:not([type]):focus:not([readonly]),
  .input-field input[type='text']:focus:not([readonly]),
  .input-field input[type='password']:focus:not([readonly]),
  .input-field input[type='email']:focus:not([readonly]),
  .input-field input[type='url']:focus:not([readonly]),
  .input-field input[type='time']:focus:not([readonly]),
  .input-field input[type='date']:focus:not([readonly]),
  .input-field input[type='datetime']:focus:not([readonly]),
  .input-field input[type='datetime-local']:focus:not([readonly]),
  .input-field input[type='tel']:focus:not([readonly]),
  .input-field input[type='number']:focus:not([readonly]),
  .input-field input[type='search']:focus:not([readonly]),
  .input-field textarea:focus:not([readonly]) {
    //margin-top: 29px;
    border-color: #1781e3;
    color: #333;
  }

  .input-field input:not([type]):focus:not([readonly]) + label,
  .input-field input[type='text']:focus:not([readonly]) + label,
  .input-field input[type='password']:focus:not([readonly]) + label,
  .input-field input[type='email']:focus:not([readonly]) + label,
  .input-field input[type='url']:focus:not([readonly]) + label,
  .input-field input[type='time']:focus:not([readonly]) + label,
  .input-field input[type='date']:focus:not([readonly]) + label,
  .input-field input[type='datetime']:focus:not([readonly]) + label,
  .input-field input[type='datetime-local']:focus:not([readonly]) + label,
  .input-field input[type='tel']:focus:not([readonly]) + label,
  .input-field input[type='number']:focus:not([readonly]) + label,
  .input-field input[type='search']:focus:not([readonly]) + label,
  .input-field textarea:focus:not([readonly]) + label {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  .input-field input:not([type]):focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='text']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='password']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='email']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='url']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='time']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='date']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='datetime']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='datetime-local']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='tel']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='number']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field input[type='search']:focus:not([readonly])::-webkit-input-placeholder,
  .input-field textarea:focus:not([readonly])::-webkit-input-placeholder {
    opacity: 0;
  }

  .input-field input:not([type]):focus:not([readonly])::-moz-placeholder,
  .input-field input[type='text']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='password']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='email']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='url']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='time']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='date']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='datetime']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='datetime-local']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='tel']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='number']:focus:not([readonly])::-moz-placeholder,
  .input-field input[type='search']:focus:not([readonly])::-moz-placeholder,
  .input-field textarea:focus:not([readonly])::-moz-placeholder {
    opacity: 0;
  }

  .input-field input:not([type]):focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='text']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='password']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='email']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='url']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='time']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='date']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='datetime']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='datetime-local']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='tel']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='number']:focus:not([readonly]):-ms-input-placeholder,
  .input-field input[type='search']:focus:not([readonly]):-ms-input-placeholder,
  .input-field textarea:focus:not([readonly]):-ms-input-placeholder {
    opacity: 0;
  }

  .input-field input:not([type]):focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='text']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='password']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='email']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='url']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='time']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='date']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='datetime']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='datetime-local']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='tel']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='number']:focus:not([readonly])::-ms-input-placeholder,
  .input-field input[type='search']:focus:not([readonly])::-ms-input-placeholder,
  .input-field textarea:focus:not([readonly])::-ms-input-placeholder {
    opacity: 0;
  }

  .input-field input:not([type]):focus:not([readonly])::placeholder,
  .input-field input[type='text']:focus:not([readonly])::placeholder,
  .input-field input[type='password']:focus:not([readonly])::placeholder,
  .input-field input[type='email']:focus:not([readonly])::placeholder,
  .input-field input[type='url']:focus:not([readonly])::placeholder,
  .input-field input[type='time']:focus:not([readonly])::placeholder,
  .input-field input[type='date']:focus:not([readonly])::placeholder,
  .input-field input[type='datetime']:focus:not([readonly])::placeholder,
  .input-field input[type='datetime-local']:focus:not([readonly])::placeholder,
  .input-field input[type='tel']:focus:not([readonly])::placeholder,
  .input-field input[type='number']:focus:not([readonly])::placeholder,
  .input-field input[type='search']:focus:not([readonly])::placeholder,
  .input-field textarea:focus:not([readonly])::placeholder {
    opacity: 0;
  }

  .input-field input:not([type]):disabled, .input-field input:not([type])[readonly],
  .input-field input[type='text']:disabled,
  .input-field input[type='text'][readonly],
  .input-field input[type='password']:disabled,
  .input-field input[type='password'][readonly],
  .input-field input[type='email']:disabled,
  .input-field input[type='email'][readonly],
  .input-field input[type='url']:disabled,
  .input-field input[type='url'][readonly],
  .input-field input[type='time']:disabled,
  .input-field input[type='time'][readonly],
  .input-field input[type='date']:disabled,
  .input-field input[type='date'][readonly],
  .input-field input[type='datetime']:disabled,
  .input-field input[type='datetime'][readonly],
  .input-field input[type='datetime-local']:disabled,
  .input-field input[type='datetime-local'][readonly],
  .input-field input[type='tel']:disabled,
  .input-field input[type='tel'][readonly],
  .input-field input[type='number']:disabled,
  .input-field input[type='number'][readonly],
  .input-field input[type='search']:disabled,
  .input-field input[type='search'][readonly],
  .input-field textarea:disabled,
  .input-field textarea[readonly] {
    opacity: 1;
    border-color: #dde3e7;
    background-color: #f8fafb;
    color: #bbb;
  }

  .input-field input:not([type]):disabled + label, .input-field input:not([type])[readonly] + label,
  .input-field input[type='text']:disabled + label,
  .input-field input[type='text'][readonly] + label,
  .input-field input[type='password']:disabled + label,
  .input-field input[type='password'][readonly] + label,
  .input-field input[type='email']:disabled + label,
  .input-field input[type='email'][readonly] + label,
  .input-field input[type='url']:disabled + label,
  .input-field input[type='url'][readonly] + label,
  .input-field input[type='time']:disabled + label,
  .input-field input[type='time'][readonly] + label,
  .input-field input[type='date']:disabled + label,
  .input-field input[type='date'][readonly] + label,
  .input-field input[type='datetime']:disabled + label,
  .input-field input[type='datetime'][readonly] + label,
  .input-field input[type='datetime-local']:disabled + label,
  .input-field input[type='datetime-local'][readonly] + label,
  .input-field input[type='tel']:disabled + label,
  .input-field input[type='tel'][readonly] + label,
  .input-field input[type='number']:disabled + label,
  .input-field input[type='number'][readonly] + label,
  .input-field input[type='search']:disabled + label,
  .input-field input[type='search'][readonly] + label,
  .input-field textarea:disabled + label,
  .input-field textarea[readonly] + label {
    color: #666;
  }

  .input-field input:not([type]):disabled,
  .input-field input[type='text']:disabled,
  .input-field input[type='password']:disabled,
  .input-field input[type='email']:disabled,
  .input-field input[type='url']:disabled,
  .input-field input[type='time']:disabled,
  .input-field input[type='date']:disabled,
  .input-field input[type='datetime']:disabled,
  .input-field input[type='datetime-local']:disabled,
  .input-field input[type='tel']:disabled,
  .input-field input[type='number']:disabled,
  .input-field input[type='search']:disabled,
  .input-field textarea:disabled {
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .input-field input:not([type]).valid, .input-field input:not([type]):valid, .input-field input:not([type]).invalid, .input-field input:not([type]):invalid,
  .input-field input[type='text'].valid,
  .input-field input[type='text']:valid,
  .input-field input[type='text'].invalid,
  .input-field input[type='text']:invalid,
  .input-field input[type='password'].valid,
  .input-field input[type='password']:valid,
  .input-field input[type='password'].invalid,
  .input-field input[type='password']:invalid,
  .input-field input[type='email'].valid,
  .input-field input[type='email']:valid,
  .input-field input[type='email'].invalid,
  .input-field input[type='email']:invalid,
  .input-field input[type='url'].valid,
  .input-field input[type='url']:valid,
  .input-field input[type='url'].invalid,
  .input-field input[type='url']:invalid,
  .input-field input[type='time'].valid,
  .input-field input[type='time']:valid,
  .input-field input[type='time'].invalid,
  .input-field input[type='time']:invalid,
  .input-field input[type='date'].valid,
  .input-field input[type='date']:valid,
  .input-field input[type='date'].invalid,
  .input-field input[type='date']:invalid,
  .input-field input[type='datetime'].valid,
  .input-field input[type='datetime']:valid,
  .input-field input[type='datetime'].invalid,
  .input-field input[type='datetime']:invalid,
  .input-field input[type='datetime-local'].valid,
  .input-field input[type='datetime-local']:valid,
  .input-field input[type='datetime-local'].invalid,
  .input-field input[type='datetime-local']:invalid,
  .input-field input[type='tel'].valid,
  .input-field input[type='tel']:valid,
  .input-field input[type='tel'].invalid,
  .input-field input[type='tel']:invalid,
  .input-field input[type='number'].valid,
  .input-field input[type='number']:valid,
  .input-field input[type='number'].invalid,
  .input-field input[type='number']:invalid,
  .input-field input[type='search'].valid,
  .input-field input[type='search']:valid,
  .input-field input[type='search'].invalid,
  .input-field input[type='search']:invalid,
  .input-field textarea.valid,
  .input-field textarea:valid,
  .input-field textarea.invalid,
  .input-field textarea:invalid {
    box-shadow: none;
    background-repeat: no-repeat;
    background-position: center right 16px;
    background-size: 22px 22px;
  }

  .input-field input:not([type]).valid ~ .icon, .input-field input:not([type]):valid ~ .icon, .input-field input:not([type]).invalid ~ .icon, .input-field input:not([type]):invalid ~ .icon,
  .input-field input[type='text'].valid ~ .icon,
  .input-field input[type='text']:valid ~ .icon,
  .input-field input[type='text'].invalid ~ .icon,
  .input-field input[type='text']:invalid ~ .icon,
  .input-field input[type='password'].valid ~ .icon,
  .input-field input[type='password']:valid ~ .icon,
  .input-field input[type='password'].invalid ~ .icon,
  .input-field input[type='password']:invalid ~ .icon,
  .input-field input[type='email'].valid ~ .icon,
  .input-field input[type='email']:valid ~ .icon,
  .input-field input[type='email'].invalid ~ .icon,
  .input-field input[type='email']:invalid ~ .icon,
  .input-field input[type='url'].valid ~ .icon,
  .input-field input[type='url']:valid ~ .icon,
  .input-field input[type='url'].invalid ~ .icon,
  .input-field input[type='url']:invalid ~ .icon,
  .input-field input[type='time'].valid ~ .icon,
  .input-field input[type='time']:valid ~ .icon,
  .input-field input[type='time'].invalid ~ .icon,
  .input-field input[type='time']:invalid ~ .icon,
  .input-field input[type='date'].valid ~ .icon,
  .input-field input[type='date']:valid ~ .icon,
  .input-field input[type='date'].invalid ~ .icon,
  .input-field input[type='date']:invalid ~ .icon,
  .input-field input[type='datetime'].valid ~ .icon,
  .input-field input[type='datetime']:valid ~ .icon,
  .input-field input[type='datetime'].invalid ~ .icon,
  .input-field input[type='datetime']:invalid ~ .icon,
  .input-field input[type='datetime-local'].valid ~ .icon,
  .input-field input[type='datetime-local']:valid ~ .icon,
  .input-field input[type='datetime-local'].invalid ~ .icon,
  .input-field input[type='datetime-local']:invalid ~ .icon,
  .input-field input[type='tel'].valid ~ .icon,
  .input-field input[type='tel']:valid ~ .icon,
  .input-field input[type='tel'].invalid ~ .icon,
  .input-field input[type='tel']:invalid ~ .icon,
  .input-field input[type='number'].valid ~ .icon,
  .input-field input[type='number']:valid ~ .icon,
  .input-field input[type='number'].invalid ~ .icon,
  .input-field input[type='number']:invalid ~ .icon,
  .input-field input[type='search'].valid ~ .icon,
  .input-field input[type='search']:valid ~ .icon,
  .input-field input[type='search'].invalid ~ .icon,
  .input-field input[type='search']:invalid ~ .icon,
  .input-field textarea.valid ~ .icon,
  .input-field textarea:valid ~ .icon,
  .input-field textarea.invalid ~ .icon,
  .input-field textarea:invalid ~ .icon {
    display: none;
  }

  .input-field input:not([type]).valid,
  .input-field input[type='text'].valid,
  .input-field input[type='password'].valid,
  .input-field input[type='email'].valid,
  .input-field input[type='url'].valid,
  .input-field input[type='time'].valid,
  .input-field input[type='date'].valid,
  .input-field input[type='datetime'].valid,
  .input-field input[type='datetime-local'].valid,
  .input-field input[type='tel'].valid,
  .input-field input[type='number'].valid,
  .input-field input[type='search'].valid,
  .input-field textarea.valid {
    border-color: #666;
    padding-right: 52px;
    color: #333;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30'%3E%3Crect fill='none' width='30' height='30'/%3E%3Cpath fill='%2325b252' d='M14,24c0.38,0,0.7-0.21,0.87-0.51l9-16c0.27-0.48,0.1-1.09-0.38-1.36c-0.48-0.27-1.09-0.1-1.36,0.38l-8.35,14.85l-6.07-6.07 c-0.39-0.39-1.02-0.39-1.41,0s-0.39,1.02,0,1.41l7,7C13.48,23.9,13.74,24,14,24z'/%3E%3C/svg%3E");
  }

  .input-field input:not([type]).invalid,
  .input-field input[type='text'].invalid,
  .input-field input[type='password'].invalid,
  .input-field input[type='email'].invalid,
  .input-field input[type='url'].invalid,
  .input-field input[type='time'].invalid,
  .input-field input[type='date'].invalid,
  .input-field input[type='datetime'].invalid,
  .input-field input[type='datetime-local'].invalid,
  .input-field input[type='tel'].invalid,
  .input-field input[type='number'].invalid,
  .input-field input[type='search'].invalid,
  .input-field textarea.invalid {
    border-color: #be0000;
    color: #d12;
  }

  .input-field input:not([type]).invalid--inline,
  .input-field input[type='text'].invalid--inline,
  .input-field input[type='password'].invalid--inline,
  .input-field input[type='email'].invalid--inline,
  .input-field input[type='url'].invalid--inline,
  .input-field input[type='time'].invalid--inline,
  .input-field input[type='date'].invalid--inline,
  .input-field input[type='datetime'].invalid--inline,
  .input-field input[type='datetime-local'].invalid--inline,
  .input-field input[type='tel'].invalid--inline,
  .input-field input[type='number'].invalid--inline,
  .input-field input[type='search'].invalid--inline,
  .input-field textarea.invalid--inline {
    padding-right: 52px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30'%3E%3Crect fill='none' width='30' height='30'/%3E%3Cpath fill='%23DD1122' d='M15,2C7.83,2,2,7.83,2,15s5.83,13,13,13s13-5.83,13-13S22.17,2,15,2z M15,26C8.93,26,4,21.07,4,15S8.93,4,15,4 s11,4.93,11,11S21.07,26,15,26z M17,21c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2S17,19.9,17,21z M13,7h4v4l-1,6h-2l-1-6V7z'/%3E%3C/svg%3E");
  }

  .input-field input:not([type]).invalid ~ .message,
  .input-field input[type='text'].invalid ~ .message,
  .input-field input[type='password'].invalid ~ .message,
  .input-field input[type='email'].invalid ~ .message,
  .input-field input[type='url'].invalid ~ .message,
  .input-field input[type='time'].invalid ~ .message,
  .input-field input[type='date'].invalid ~ .message,
  .input-field input[type='datetime'].invalid ~ .message,
  .input-field input[type='datetime-local'].invalid ~ .message,
  .input-field input[type='tel'].invalid ~ .message,
  .input-field input[type='number'].invalid ~ .message,
  .input-field input[type='search'].invalid ~ .message,
  .input-field textarea.invalid ~ .message {
    max-height: 50px;
    margin-top: 6px;
    opacity: 1;
    color: #d12;
  }

  .input-field input:not([type]).validate + label,
  .input-field input[type='text'].validate + label,
  .input-field input[type='password'].validate + label,
  .input-field input[type='email'].validate + label,
  .input-field input[type='url'].validate + label,
  .input-field input[type='time'].validate + label,
  .input-field input[type='date'].validate + label,
  .input-field input[type='datetime'].validate + label,
  .input-field input[type='datetime-local'].validate + label,
  .input-field input[type='tel'].validate + label,
  .input-field input[type='number'].validate + label,
  .input-field input[type='search'].validate + label,
  .input-field textarea.validate + label {
    width: 100%;
    pointer-events: none;
  }

  .input-field input:not([type]).is-fixed,
  .input-field input[type='text'].is-fixed,
  .input-field input[type='password'].is-fixed,
  .input-field input[type='email'].is-fixed,
  .input-field input[type='url'].is-fixed,
  .input-field input[type='time'].is-fixed,
  .input-field input[type='date'].is-fixed,
  .input-field input[type='datetime'].is-fixed,
  .input-field input[type='datetime-local'].is-fixed,
  .input-field input[type='tel'].is-fixed,
  .input-field input[type='number'].is-fixed,
  .input-field input[type='search'].is-fixed,
  .input-field textarea.is-fixed {
    margin-top: 29px;
  }

  .input-field input:not([type]).is-fixed + label,
  .input-field input[type='text'].is-fixed + label,
  .input-field input[type='password'].is-fixed + label,
  .input-field input[type='email'].is-fixed + label,
  .input-field input[type='url'].is-fixed + label,
  .input-field input[type='time'].is-fixed + label,
  .input-field input[type='date'].is-fixed + label,
  .input-field input[type='datetime'].is-fixed + label,
  .input-field input[type='datetime-local'].is-fixed + label,
  .input-field input[type='tel'].is-fixed + label,
  .input-field input[type='number'].is-fixed + label,
  .input-field input[type='search'].is-fixed + label,
  .input-field textarea.is-fixed + label {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  .input-field--fixed input:not([type]),
  .input-field--fixed input[type='text'],
  .input-field--fixed input[type='password'],
  .input-field--fixed input[type='email'],
  .input-field--fixed input[type='url'],
  .input-field--fixed input[type='time'],
  .input-field--fixed input[type='date'],
  .input-field--fixed input[type='datetime'],
  .input-field--fixed input[type='datetime-local'],
  .input-field--fixed input[type='tel'],
  .input-field--fixed input[type='number'],
  .input-field--fixed input[type='search'],
  .input-field--fixed textarea {
    margin-top: 29px;
  }

  .input-field input:not([type]):first-child, .input-field input:not([type]),
  .input-field input[type='text']:first-child,
  .input-field input[type='text'],
  .input-field input[type='password']:first-child,
  .input-field input[type='password'],
  .input-field input[type='email']:first-child,
  .input-field input[type='email'],
  .input-field input[type='url']:first-child,
  .input-field input[type='url'],
  .input-field input[type='time']:first-child,
  .input-field input[type='time'],
  .input-field input[type='date']:first-child,
  .input-field input[type='date'],
  .input-field input[type='datetime']:first-child,
  .input-field input[type='datetime'],
  .input-field input[type='datetime-local']:first-child,
  .input-field input[type='datetime-local'],
  .input-field input[type='tel']:first-child,
  .input-field input[type='tel'],
  .input-field input[type='number']:first-child,
  .input-field input[type='number'],
  .input-field input[type='search']:first-child,
  .input-field input[type='search'],
  .input-field textarea:first-child,
  .input-field textarea {
    margin-top: 29px;
  }

  .input-field input:not([type]):first-child + label, .input-field input:not([type]) + label,
  .input-field input[type='text']:first-child + label,
  .input-field input[type='text'] + label,
  .input-field input[type='password']:first-child + label,
  .input-field input[type='password'] + label,
  .input-field input[type='email']:first-child + label,
  .input-field input[type='email'] + label,
  .input-field input[type='url']:first-child + label,
  .input-field input[type='url'] + label,
  .input-field input[type='time']:first-child + label,
  .input-field input[type='time'] + label,
  .input-field input[type='date']:first-child + label,
  .input-field input[type='date'] + label,
  .input-field input[type='datetime']:first-child + label,
  .input-field input[type='datetime'] + label,
  .input-field input[type='datetime-local']:first-child + label,
  .input-field input[type='datetime-local'] + label,
  .input-field input[type='tel']:first-child + label,
  .input-field input[type='tel'] + label,
  .input-field input[type='number']:first-child + label,
  .input-field input[type='number'] + label,
  .input-field input[type='search']:first-child + label,
  .input-field input[type='search'] + label,
  .input-field textarea:first-child + label,
  .input-field textarea + label {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }


  // Textarea styles
// -------------------------

.input-multiline, // DEPRECATED
.input-field--multiline {
  textarea {
    width: 100%;
    height: auto;

    padding: 11px 16px;

    resize: none;
    box-sizing: border-box;
  }
}

  /* Radio */


  .radio + .radio {
    margin-top: 14px;
  }

  .radio.add-space {
    margin-bottom: 24px;
  }

  .radio p {
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0;
    padding-left: 36px;
    color: #666;
  }

  .radio [type='radio']:not(:checked),
  .radio [type='radio']:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }

  .radio [type='radio'] + label {
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -.1px;
    font-size: 18px;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    padding-left: 30px;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .radio [type='radio'] + label::before, .radio [type='radio'] + label::after {
    position: absolute;
    top: -1px;
    left: 6px;
    z-index: 0;
    margin-top: 1px;
  }

  .radio [type='radio'] + label::before {
    transition: all 120ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    width: 22px;
    height: 22px;
  }

  .radio [type='radio'] + label::after {
    transition: all 120ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 10px;
    height: 10px;
    font-family: helloclass-icons;
      font-size: pxToRem(16);
  }

  .radio [type='radio']:not(:checked) + label::after {
    -webkit-transform: scale(1);
    transform: scale(1);
      content: "\e913";
  }

  .radio [type='radio']:checked + label::after {
    -webkit-transform: scale(1);
    transform: scale(1);
      content: "\e914";
  }

  .radio [type='radio']:disabled + label {
    opacity: .4;
    pointer-events: none;
  }

  .radio [type='radio']:disabled + label::before {
    border-color: #adadad;
    background-color: transparent;
  }

  .radio [type='radio']:disabled:hover + label::before {
    border: 2px solid #adadad;
  }

  .radio [type='radio']:disabled:hover + label::after {
    background: none;
  }

  .radio [type='radio']:disabled:checked + label::before {
    border: 2px solid #adadad;
  }

  .radio [type='radio']:disabled:checked + label::after {
    background-color: #adadad;
  }

  .radio [type='radio']:disabled:not(:checked) + label::before {
    border-color: #adadad;
  }

  .radio--only [type='radio'] + label {
    padding-left: 22px;
  }

  .form-inline .radio {
    display: inline-block;
    vertical-align: top;
  }

  .form-inline .radio + .radio {
    margin-top: 0;
    margin-left: 14px;
  }


  /* checkboxes */
  .checkbox + .checkbox {
    margin-top: 24px;
  }

  .checkbox.add-space {
    margin-bottom: 24px;
  }

  .checkbox p {
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    margin-top: 5px;
    margin-bottom: 0;
    padding-left: 36px;
  }

  .checkbox .message {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
    transition: 150ms all cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 10;
  }

  .checkbox.invalid > .message {
    max-height: 50px;
    margin-top: 6px;
    opacity: 1;
    color: #d12;
  }

  .checkbox [type='checkbox']:not(:checked),
  .checkbox [type='checkbox']:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }

  .checkbox [type='checkbox'] + label {
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -.1px;
    font-size: 18px;
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    padding-left: 36px;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .checkbox [type='checkbox'] + label::before, .checkbox [type='checkbox'] + label::after {
    position: absolute;
    top: 1px;
    left: 0;
    z-index: 0;
    content: '';
  }

  .checkbox [type='checkbox'] + label::before {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    border: 2px solid #adadad;
    border-radius: 5px;
    width: 22px;
    height: 22px;
  }

  .checkbox [type='checkbox'] + label::after {
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 22px;
    height: auto;
    text-align: center;
    line-height: 22px;
    color: #adadad;
    font-family: sdx-icons;
    font-size: 18px;
    content: "";
  }

  .checkbox [type='checkbox']:hover + label::before {
    border: 2px solid #adadad;
    background: transparent;
  }

  .checkbox [type='checkbox']:hover + label::after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    color: #adadad;
  }

  .checkbox [type='checkbox']:focus + label::before {
    border: 2px solid #1781e3;
  }

  .checkbox [type='checkbox']:focus + label::after {
    color: #1781e3;
  }

  .checkbox [type='checkbox']:checked + label::before {
    border: 2px solid #1781e3;
    background: transparent;
  }

  .checkbox [type='checkbox']:checked + label::after {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: #1781e3;
  }

  .checkbox [type='checkbox']:checked:focus + label::before {
    border: 2px solid #1781e3;
  }

  .checkbox [type='checkbox']:checked:focus + label::after {
    color: #1781e3;
  }

  .checkbox [type='checkbox']:disabled + label,
  .checkbox [type='checkbox']:disabled + label + p {
    opacity: .4;
    pointer-events: none;
  }

  .hiddenscroll.touchevents .checkbox [type='checkbox']:hover + label::before {
    border: 2px solid #adadad;
    background: transparent;
  }

  .hiddenscroll.touchevents .checkbox [type='checkbox']:hover + label::after {
    -webkit-transform: scale(0);
    transform: scale(0);
    color: #adadad;
  }

  .hiddenscroll.touchevents .checkbox [type='checkbox']:focus + label::before {
    border: 2px solid #1781e3;
  }

  .hiddenscroll.touchevents .checkbox [type='checkbox']:focus + label::after {
    color: #1781e3;
  }

  .hiddenscroll.touchevents .checkbox [type='checkbox']:checked + label::before {
    border: 2px solid #1781e3;
    background: transparent;
  }

  .hiddenscroll.touchevents .checkbox [type='checkbox']:checked + label::after {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: #1781e3;
  }

  .hiddenscroll.touchevents .checkbox [type='checkbox']:checked:focus + label::before {
    border: 2px solid #1781e3;
  }

  .hiddenscroll.touchevents .checkbox [type='checkbox']:checked:focus + label::after {
    color: #1781e3;
  }

  .checkbox--only [type='checkbox'] + label {
    display: inline;
    padding-left: 22px;
  }

  .checkbox--inverted [type='checkbox']:hover + label::before {
    border: 2px solid #0851da;
    background: #0851da;
  }

  .checkbox--inverted [type='checkbox']:hover + label::after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    color: #fff;
  }

  .checkbox--inverted [type='checkbox']:focus + label::before {
    border: 2px solid #0851da;
  }

  .checkbox--inverted [type='checkbox']:focus + label::after {
    color: #fff;
  }

  .checkbox--inverted [type='checkbox']:checked + label::before {
    border: 2px solid #1781e3;
    background: #1781e3;
  }

  .checkbox--inverted [type='checkbox']:checked + label::after {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: #fff;
  }

  .checkbox--inverted [type='checkbox']:checked:focus + label::before {
    border: 2px solid #0851da;
  }

  .checkbox--inverted [type='checkbox']:checked:focus + label::after {
    color: #fff;
  }

  .hiddenscroll.touchevents .checkbox--inverted [type='checkbox']:hover + label::before {
    border: 2px solid #adadad;
    background: transparent;
  }

  .hiddenscroll.touchevents .checkbox--inverted [type='checkbox']:hover + label::after {
    -webkit-transform: scale(0);
    transform: scale(0);
    color: #adadad;
  }

  .hiddenscroll.touchevents .checkbox--inverted [type='checkbox']:focus + label::before {
    border: 2px solid #0851da;
  }

  .hiddenscroll.touchevents .checkbox--inverted [type='checkbox']:focus + label::after {
    color: #fff;
  }

  .hiddenscroll.touchevents .checkbox--inverted [type='checkbox']:checked + label::before {
    border: 2px solid #1781e3;
    background: #1781e3;
  }

  .hiddenscroll.touchevents .checkbox--inverted [type='checkbox']:checked + label::after {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: #fff;
  }

  .hiddenscroll.touchevents .checkbox--inverted [type='checkbox']:checked:focus + label::before {
    border: 2px solid #0851da;
  }

  .hiddenscroll.touchevents .checkbox--inverted [type='checkbox']:checked:focus + label::after {
    color: #fff;
  }

  .form-inline .checkbox {
    display: inline-block;
    vertical-align: top;
  }

  .form-inline .checkbox + .checkbox {
    margin-top: 0;
    margin-left: 14px;
  }

  .form-group label,
  .form-group .label {
    display: block;
    margin-bottom: 8px;
  }

  /* select */
  .select {
    line-height: 24px;
    letter-spacing: -.1px;
    font-size: 18px;
    position: relative;
    margin-bottom: 16px;
    outline: none;
    cursor: pointer;
    z-index: 16;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .select:last-of-type {
    margin-bottom: 0;
  }

  .select select {
    display: none;
  }

  .select .select__button {
    position: relative;
    width: 100%;
    height: 48px;
  }

  .select .select__button:hover .select__thumb {
    background: #1781e3;
    color: #fff;
  }

  .select .select__button:hover .select__thumb .thumb-icon {
    position: relative;
  }

  .select .select__button:hover .select__thumb .thumb-icon::before, .select .select__button:hover .select__thumb .thumb-icon::after {
    position: absolute;
    top: 50%;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 3px;
    background: #fff;
    width: 20px;
    height: 3px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    content: '';
  }

  .select .select__button:hover .select__thumb .thumb-icon::before {
    left: 0;
  }

  .select .select__button:hover .select__thumb .thumb-icon::after {
    left: 15px;
  }

  .select .select__thumb {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 501;
    border-radius: 0 5px 5px 0;
    cursor: inherit;
    width: 34px;
    height: inherit;
    color: #1781e3;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  .select .select__thumb .thumb-icon {
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    margin: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  .select .select__thumb .thumb-icon::before, .select .select__thumb .thumb-icon::after {
    position: absolute;
    top: 50%;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 3px;
    background: #1781e3;
    width: 20px;
    height: 3px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    content: '';
  }

  .select .select__thumb .thumb-icon::before {
    left: 0;
  }

  .select .select__thumb .thumb-icon::after {
    left: 15px;
  }

  .select .select__thumb .loader-spinner {
    display: none;
  }

  .select .select__placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #fff;
    display: block;
    position: relative;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    cursor: inherit;
    padding: 12px 55px 12px 15px;
    width: 100%;
    height: inherit;
    -webkit-appearance: none;
  }

  .select .select__dropdown {
    position: absolute;
    right: 0;
    left: 0;
    margin-bottom: 20px;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    z-index: 19;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.1);
    background: #fff;
    width: 100%;
    height: auto;
    max-height: 0;
    overflow: hidden;
    color: #333;
  }

  .select .select__dropdown .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid #d6d6d6;
    border-top: 0;
    padding: 12px 15px 15px;
    width: 100%;
    height: 48px;
  }

  .select .select__dropdown .dropdown-item:last-child {
    border-radius: 0 0 5px 5px;
  }

  .select .select__dropdown .dropdown-item:hover, .select .select__dropdown .dropdown-item--focused {
    border-color: #1781e3;
    background: #1781e3;
    color: #fff;
  }

  .select .select__dropdown .dropdown-item--selected {
    color: #25b252;
  }

  .select .select__dropdown .dropdown-item--selected::before {
    float: right;
    margin-right: -7px;
    font-family: sdx-icons;
    font-size: 16px;
    content: "";
  }

  .select .select__dropdown .dropdown-item--disabled, .select .select__dropdown .dropdown-item--disabled:hover {
    border-color: #d6d6d6;
    background: none;
    cursor: not-allowed;
    color: rgba(51, 51, 51, 0.4);
  }

  .select .message {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
    transition: 150ms all cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 10;
  }

  .select.invalid > .message {
    max-height: 50px;
    margin-top: 6px;
    opacity: 1;
    color: #d12;
  }

  .select .dropdown-group__item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid #d6d6d6;
    border-top: 0;
    padding: 12px 15px 15px;
    width: 100%;
    height: 48px;
    background-color: #eef3f6;
    color: #666;
    pointer-events: none;
  }

  .select .dropdown-group__item:hover {
    cursor: default;
  }

  .select .dropdown-group .dropdown-item:last-child {
    border-radius: 0;
  }

  .select .dropdown-group:last-child .dropdown-item:last-child {
    border-radius: 0 0 5px 5px;
  }

  .select--closed .select__thumb .thumb-icon::before {
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
  }

  .select--closed .select__thumb .thumb-icon::after {
    -webkit-transform: rotate(-35deg);
    transform: rotate(-35deg);
  }

  .select--closed .select__dropdown {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    opacity: .2;
  }

  .select--open {
    z-index: 19;
  }

  .select--open .select__thumb {
    border-radius: 0 5px 0 0;
  }

  .select--open .select__thumb .thumb-icon::before {
    -webkit-transform: rotate(-35deg);
    transform: rotate(-35deg);
  }

  .select--open .select__thumb .thumb-icon::after {
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
  }

  .select--open .select__placeholder {
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.1);
    color: #1781e3;
  }

  .select--open .select__dropdown {
    max-height: 512px;
    overflow-y: auto;
  }

  .select--disabled {
    opacity: .4;
    cursor: not-allowed;
  }

  .select--disabled .select__button:hover .select__thumb {
    background: none;
    color: #1781e3;
  }

  .select--disabled .select__button:hover .select__thumb .thumb-icon {
    position: relative;
  }

  .select--disabled .select__button:hover .select__thumb .thumb-icon::before, .select--disabled .select__button:hover .select__thumb .thumb-icon::after {
    position: absolute;
    top: 50%;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 3px;
    background: #1781e3;
    width: 20px;
    height: 3px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    content: '';
  }

  .select--disabled .select__button:hover .select__thumb .thumb-icon::before {
    left: 0;
  }

  .select--disabled .select__button:hover .select__thumb .thumb-icon::after {
    left: 15px;
  }

  .select--filterable .select__dropdown .dropdown-item strong {
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .select--filterable .select__dropdown .dropdown-item:hover strong {
    color: #fff !important;
  }

  .select--filterable .select__dropdown .dropdown-item.dropdown-item--selected strong {
    font-weight: inherit;
  }

  .select--filterable .select__dropdown .dropdown-item:not(.dropdown-item--selected) strong {
    color: #0083ea;
  }

  .select--filterable .select__placeholder:focus:not([readonly]) {
    border-color: #1781e3;
  }

  .select--loading {
    pointer-events: none;
  }

  .select--loading .select__thumb .loader-spinner {
    display: block;
    top: 50%;
    left: 50%;
    margin-top: -12.5px;
    margin-left: -12.5px;
  }

  .select--loading .select__thumb .thumb-icon {
    display: none;
  }

  .select--minimal .select__button:hover .select__thumb {
    background: none;
    color: #0851da;
  }

  .select--minimal .select__button:hover .select__thumb .thumb-icon {
    position: relative;
  }

  .select--minimal .select__button:hover .select__thumb .thumb-icon::before, .select--minimal .select__button:hover .select__thumb .thumb-icon::after {
    position: absolute;
    top: 50%;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 3px;
    background: #0851da;
    width: 20px;
    height: 3px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    content: '';
  }

  .select--minimal .select__button:hover .select__thumb .thumb-icon::before {
    left: 0;
  }

  .select--minimal .select__button:hover .select__thumb .thumb-icon::after {
    left: 15px;
  }

  .select--minimal .select__placeholder {
    border: 0;
    border-bottom: 1px solid #d6d6d6;
    border-radius: 0;
    box-shadow: none;
    color: #333;
  }

  .select--minimal .select__dropdown {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.15);
  }

  .select--minimal .select__dropdown .dropdown-item {
    border: 0;
    border-bottom: 1px solid #d6d6d6;
    border-radius: 0;
  }

  .select--minimal .select__dropdown .dropdown-item:last-child {
    border-bottom: 0;
    border-radius: 0;
  }

  .select--minimal.select--open .select__placeholder {
    border-color: transparent;
  }

  .select--minimal.select--tabs .select__placeholder {
    border: 0;
    border-bottom: 4px solid #1781e3;
    border-radius: 0;
    box-shadow: none;
    color: #333;
  }

  select {
    display: block;
    position: relative;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
    padding: 12px 55px 12px 15px;
    width: auto;
    height: 48px;
    color: #333;
    -webkit-appearance: none;
  }

  .bg--dark label {
    font-weight: 400;
  }


  @mixin background($path, $ext, $w: auto, $h: auto, $posX: left, $posY: top, $repeat: no-repeat) {
    $at1x-path: '#{$path}.#{$ext}';
    $at2x-path: '#{$path}@2x.#{$ext}';

    background-size: $w $h;
    background-position: $posX $posY;
    background-repeat: $repeat;

    background-image: url('#{$at1x-path}');

    @include retina {
      background-image: url('#{$at2x-path}');
    }
  }

  // Retina spritesheet
  // -------------------------

  @mixin spritesheet($path, $ext, $w: auto, $h: auto, $sw: auto, $sh: auto, $posX: 0, $posY: 0) {
    @include background($path, $ext, $sw, $sh, $posX, $posY);

    width: $w;
    height: $h;

    backface-visibility: hidden;
  }

  // Retina spritesheet animation
  // -------------------------

  @mixin spritesheet-animation($id, $path, $ext, $w, $h, $sw, $sh, $duration, $steps, $offsetX: 0, $offsetY: 0, $loop: infinite) {
    @include spritesheet($path, $ext, $w, $h, $sw, $sh, $offsetX, $offsetY);

    $animation: anim-sheet-#{$id};

    // The animation of the sprite
    @keyframes #{$animation} {
      0% {
        background-position: $offsetX $offsetY;
      }

      100% {
        background-position: #{$offsetX - $sw} $offsetY;
      }
    }

    animation: $animation $duration steps($steps) $loop;
  }


  $loader-spinner-large: 50px !default;
  $loader-spinner-small: 25px !default;

    // Spinner
    $loader-sw-large:           3750px;
    $loader-sh-large:           50px;
    $loader-sw-small:           1875px;
    $loader-sh-small:           25px;
    $loader-duration:           1.25s;
    $loader-steps:              75;


    @mixin retina {
        @media only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min--moz-device-pixel-ratio: 2),
          only screen and (-o-min-device-pixel-ratio: 2 / 1),
          only screen and (min-device-pixel-ratio: 2),
          only screen and (min-resolution: 192dpi),
          only screen and (min-resolution: 2dppx) {
            @content;
        }
      }

      @mixin print {
        @media print {
          @content;
        }
      }

  /* spinner */
// Loader Spinner
// --------------------------------------------------

/*doc
---
title: Loader spinner
name: 02-components-21-loader-spinner-01
category: Components - Loading Spinner
---

The loader spinner has two base sizes small and large for a variety of different uses.

#### Navy to red Loader

```html_example
<div class="loader-spinner loader-spinner--small"></div>
<div class="loader-spinner loader-spinner--large"></div>
```

#### Navy to blue Loader

```html_example
<div class="loader-spinner loader-spinner--small loader-spinner--plain"></div>
<div class="loader-spinner loader-spinner--large loader-spinner--plain"></div>
```
*/

/*doc
---
title: Loader spinner on dark backgrounds
name: 02-components-21-loader-spinner-02
category: Components - Loading Spinner
---

Loader spinners are also available on dark backgrounds, just annotate the parent background element
with the `.bg--dark` class and the loader will automatically adjust their styling accordingly.

```html_example
<div class="bg bg--dark">
  <div class="loader-spinner loader-spinner--small"></div>
  <div class="loader-spinner loader-spinner--large"></div>
</div>
```
*/

// --------------------------------------------------


// Loader Spinner Base
// --------------------------------------------------

.loader-spinner {
    position: relative;

    &,
    &--large {
      @include spritesheet-animation(
          navy-large,
          '../images/loader-spinner-navy-large',
          'png',
          $loader-spinner-large,
          $loader-spinner-large,
          $loader-sw-large,
          $loader-sh-large,
          $loader-duration,
          $loader-steps
      );

      &.loader-spinner--plain {
        @include spritesheet-animation(
            plain-large,
            '../images/loader-spinner-plain-large',
            'png',
            $loader-spinner-large,
            $loader-spinner-large,
            $loader-sw-large,
            $loader-sh-large,
            $loader-duration,
            $loader-steps
        );
      }
    }

    &--small {
      @include spritesheet-animation(
          navy-small,
          '../images/loader-spinner-navy-small',
          'png',
          $loader-spinner-small,
          $loader-spinner-small,
          $loader-sw-small,
          $loader-sh-small,
          $loader-duration,
          $loader-steps
      );

      &.loader-spinner--plain {
        @include spritesheet-animation(
            plain-small,
            '../images/loader-spinner-plain-small',
            'png',
            $loader-spinner-small,
            $loader-spinner-small,
            $loader-sw-small,
            $loader-sh-small,
            $loader-duration,
            $loader-steps
        );
      }
    }
  }


  // Dark Background
  // -------------------------

  .bg--dark {
    .loader-spinner {
      &,
      &--large {
        @include spritesheet-animation(
            dark-large,
            '../images/loader-spinner-dark-large',
            'png',
            $loader-spinner-large,
            $loader-spinner-large,
            $loader-sw-large,
            $loader-sh-large,
            $loader-duration,
            $loader-steps
        );
      }

      &--small {
        @include spritesheet-animation(
            dark-small,
            '../images/loader-spinner-dark-small',
            'png',
            $loader-spinner-small,
            $loader-spinner-small,
            $loader-sw-small,
            $loader-sh-small,
            $loader-duration,
            $loader-steps
        );
      }
    }
  }
