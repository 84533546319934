@use "sass:math";

.event {

  position: relative;
  border-bottom: 1px solid $color-lighter-gray;

  .fade-out__gradient {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $color-almost-white;
    & > .event__info--overview {
      background-color: $color-almost-white;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    position: absolute;
    right: 0;
    left: 0;
    padding: 0;
    border: 0;
    height: $classbook-cell-height;
    overflow-y: hidden;

    .fade-out__gradient {
      display: block;
    }

    &__wrapper {
      // height: calc(100% - 1px);
      width: 100%;
      background-color: $color-white;
      position: relative;


      &:hover {
        // background-color: $color-lighter-gray;

        .fade-out__gradient {
          background: linear-gradient(to right, transparentize($color: $color-lighter-gray, $amount: 1)() 0%, $color-lighter-gray);
        }

        .fade-out__gradient--bottom {
          background: linear-gradient(to bottom, transparentize($color: $color-lighter-gray, $amount: 1)() 0%, $color-lighter-gray);
        }
      }
    }
  }

  &--detail {
    height: auto;
    border: 0;
    margin: 0 0 $vertical-margin 0;
    opacity: 1;
    word-wrap: break-word;

    .event {
        @media screen and (min-width: $screen-md-min) {
          padding-left: $gutter-width;
        }

        &:hover {
          background: inherit;
          cursor: auto;
        }
      }

    .event__header {
      display: flex;
      justify-content: space-between;
    }

    .event__action {
        order: 2;
    }

    .event__meta {
        order: 1;
    }

    .event__wrapper {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }

      .fade-out__gradient {
        display: none;
      }
    }

    .event__attachments {
        .event-attachment {
            margin-left: 2*$gutter-width;
        }
    }

    @media screen and (min-width: $screen-md-min) {
      position: relative;
      right: inherit;
      left: inherit;
      padding: $gutter-width $gutter-width $gutter-width 0;

      .event__content {
        position: static;
        margin-left: 2*$gutter-width;
        // margin-right: $gutter-width;
        top: inherit;
        left: inherit;
        bottom: inherit;
        right: inherit;
      }

      .event__colorbar {
        left: $gutter-width;
      }
    }

  }

  //to do: precalc in scss somehow?
  // generates event--start-x classes
  @each $index in $slots-start {
    &--start-#{$index} {
        @media screen and (min-width: $screen-md-min) {
          // top: calc(#{$index}*100%/7);
          top: math.div($index*100%, $numSlots);
      }
    }
  }

  @each $index in $slots-length {
      // generates event--length-x classes
      &--length-#{$index} {
        @media screen and (min-width: $screen-md-min) {
          height: math.div($index *100%, $numSlots);
          // height: $index * $classbook-cell-height - $classbook-cell-margin-rem;
      }
    }
  }

  &__description_input {
    min-height: pxToRem(200);
  }

  &__colorbar {
    width: pxToRem(5);
    // display: inline-block;
    position: absolute;
    top: $classbook-cell-margin;
    bottom: $classbook-cell-margin;
    // border-bottom: 1px solid $color-white;
    // border-top: 1px solid $color-white;
    z-index: 10;

    @media screen and (min-width: $screen-md-min) {
      left: $classbook-cell-margin-rem;
    }
  }

  &__content {
    position: static;
    padding-top: $classbook-cell-margin;
    margin: $classbook-cell-margin $classbook-cell-margin $classbook-cell-margin $gutter-width;
    display: block;
    background-color: transparent;

    @media screen and (min-width: $screen-md-min) {
      padding-top: 0;
      position: absolute;
      margin-left: pxToRem(17);
      top: $classbook-cell-margin;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__group {
    width: 70%;
    display: inline-block;
    @media screen and (min-width: $screen-md-min) {
      width: 60%;
    }
  }

  &__label {
    margin-right: $gutter-width;

    @each $name, $color in $colors {
      &--#{"" + $name} {
        color: $color
      }
    }
  }

  &__navigation {
    width: 25%;
    display: none;

    @media screen and (min-width: $screen-md-min) {
      display: inline-block;
      width: 35%;
    }

    & > button {
      // padding-left: 0;
      // padding-right: 0;
      padding: pxToRem(3);

      &:hover {
        position: relative;
        top: pxToRem(1);
        left: pxToRem(1);
      }
    }
  }

  &__pagination {
    display: inline;
  }

  &__title {
    margin-top: 0;
    line-height: inherit;
  }

  &__info {
    display: flex;

    margin-left: $gutter-width;
    margin-bottom: $gutter-width;

    flex-flow: row;

    &--overview {
      margin-bottom: pxToRem(5);
    }

    @media screen and (min-width: $screen-md-min) {
        flex-flow: column;

        margin-left: 2*$gutter-width;

        &--overview {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $color-white;
          margin-bottom: pxToRem(1);
          padding-bottom: pxToRem(4);
          margin-left: $gutter-width;
        }
    }
  }

  &__action {
      order: 1;
      z-index: 15;
  }

  &__meta {
      order: 2;
  }
}

.edit-actions {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid $color-light-gray;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.35);

  &__action {
    padding: pxToRem(10);
    display: inline-block;

    &:first-child {
      border-right: 1px solid $color-light-gray;
    }
  }

  &__icon {
    position: relative;
    top: 0;
    left: 0;

    &:active {
      top: 1px;
      left: 1px;
    }
  }
}

.event-meta-list {
    &__item {
        display: inline-block;
        margin-left: pxToRem(10);

        &--is-hidden {
            display: none;
        }


        &:first-child {
            margin-left: 0;
        }

        span {
            position: relative;
            top: pxToRem(1);
            margin-right: pxToRem(5);
        }
    }
}

.event-form-main {
  position: relative;
  margin-top: -$header-height;
  margin-bottom: $vertical-margin;
  padding-top: $header-height;

  @media screen and (min-width: $screen-md-min) {
      margin-top: 0;
  }
}

.meta-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.privacy-heading {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    color: $color-text-gray;
    font-size: $font-size-small;
}

.time-selection {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $screen-md-min) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

  &__item {
      &--30 {
        @media screen and (min-width: $screen-md-min) {
           flex-basis: 30%
        }
      }
      &--25 {
        @media screen and (min-width: $screen-md-min) {
           flex-basis: 25%
        }
      }
  }
}

.recurrence-selection {
    display: flex;
    flex-direction: column;
    gap: $gutter-width;

    @media screen and (min-width: $screen-md-min) {
      flex-direction: row;
      align-items: center;
    }
}

.date-selection {
    border: 1px solid #d6d6d6;
    height: pxToRem(48);
    border-radius: 5px;
    padding-inline: math.div($gutter-width, 2);
}

.eventform-section {
    margin-bottom: pxToRem(30);

    @media screen and (min-width: $screen-md-min) {
      margin-bottom: pxToRem(60);
    }
}
