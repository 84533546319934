@font-face {
  font-family: 'TheSansB5';
  src: url("../fonts/TheSaB5_.woff") format('woff'),
    url('../fonts/TheSaB5_.svg') format('svg'),
    url('../fonts/TheSaB5_.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'TheSans-B7Bold';
    src: url('../fonts/TheSans-B7Bold.eot');
    src: url('../fonts/TheSans-B7Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TheSans-B7Bold.woff2') format('woff2'),
        url('../fonts/TheSans-B7Bold.woff') format('woff'),
        url('../fonts/TheSans-B7Bold.ttf') format('truetype'),
        url('../fonts/TheSans-B7Bold.svg#TheSans-B7Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TheSans-B5Plain';
    src: url('../fonts/TheSans-B5Plain.eot');
    src: url('../fonts/TheSans-B5Plain.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TheSans-B5Plain.woff2') format('woff2'),
        url('../fonts/TheSans-B5Plain.woff') format('woff'),
        url('../fonts/TheSans-B5Plain.ttf') format('truetype'),
        url('../fonts/TheSans-B5Plain.svg#TheSans-B5Plain') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TheSans-B3Light';
    src: url('../fonts/TheSans-B3Light.eot');
    src: url('../fonts/TheSans-B3Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TheSans-B3Light.woff2') format('woff2'),
        url('../fonts/TheSans-B3Light.woff') format('woff'),
        url('../fonts/TheSans-B3Light.ttf') format('truetype'),
        url('../fonts/TheSans-B3Light.svg#TheSans-B3Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TheSans-B6SemiBold';
    src: url('../fonts/TheSans-B6SemiBold.eot');
    src: url('../fonts/TheSans-B6SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TheSans-B6SemiBold.woff2') format('woff2'),
        url('../fonts/TheSans-B6SemiBold.woff') format('woff'),
        url('../fonts/TheSans-B6SemiBold.ttf') format('truetype'),
        url('../fonts/TheSans-B6SemiBold.svg#TheSans-B6SemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TheSans-B4SemiLight';
    src: url('../fonts/TheSans-B4SemiLight.eot');
    src: url('../fonts/TheSans-B4SemiLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TheSans-B4SemiLight.woff2') format('woff2'),
        url('../fonts/TheSans-B4SemiLight.woff') format('woff'),
        url('../fonts/TheSans-B4SemiLight.ttf') format('truetype'),
        url('../fonts/TheSans-B4SemiLight.svg#TheSans-B4SemiLight') format('svg');
    font-weight: normal;
    font-style: normal;
}


h4 {
  margin-bottom: pxToRem(5);
}

h1, h2, h3, h4, h5, h6, p, div {
    font-family: 'TheSans-B4SemiLight', Helvetica, sans-serif;

    &.small {
      font-size: $font-size-small;
    }

    &.lead {
      font-size: $font-size-lead;
    }

    &.inverted {
      color: $color-white;

      &--highlight {
        color: $color-primary-blue;
      }
    }
}

p {
  font-size: $font-size-text;
}

.text-gray {
    color: $color-text-gray
}

.text-size-normal {
  font-size: $font-size-text;
}

.text-size-small {
  font-size: $font-size-small;
}

.text-size-form {
  font-size: $font-size-form;
}

.text-blue {
  color: $color-blue;
}

.text-emph-blue {
    color: $color-primary-blue;
    font-size: $font-size-h3;
}

.icon-size {
  font-size: $font-size-lead;
}

label, .hc-label {
    font-family: "TheSansB5", Helvetica, sans-serif;
    font-size: $font-size-text;
    font-weight: normal;
}

/* new headings */

.heading-1 {
    @include heading;
    font-size: $font-size-heading1;
    line-height: pxToRem(34);
}

.heading-2 {
    @include heading;
    font-size: $font-size-heading2;
    margin-bottom: $gutter-width;
}

.heading-3 {
    font-size: $font-size-heading3;
    margin-bottom: pxToRem(20);
    color: $color-black !important;
}

.text-gray {
    color: $color-text-gray;
}

.text-emph {
    @include emph-text;
}

