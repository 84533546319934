@use "sass:math";

$attachment-height: pxToRem(180);
$attachment-width: pxToRem(320);

.attachment {
    margin-top: $vertical-margin;
    display: flex;

    &__action {
        display: inline-block;
        margin: 0 math.div($vertical-margin, 2);
    }
}

.attachment-list {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--text {
        flex-direction: column;
    }
}

.attachment-text {
    &__filename {
        display: inline-block;
    }
}

.preview {
    max-width: $attachment-width;
    max-height: $attachment-height;
}

.preview-content {
    &--document {
        width: $attachment-width;
        height: $attachment-height;
    }
}

.event-image {
    &__thumbnail {
        max-width: $attachment-width;
        max-height: $attachment-height;
    }
}

.event-document {

    text-align: center;
    padding-top: $vertical-margin;

    @each $name, $color in $colors {
        &--#{"" + $name} {
          background-color: lighten($color, 50%);

          & .icon-document {
              color: $color;
          }
        }
    }

    &__meta {
        color: $color-darker;
    }
}

.attachment-action {
    color: $color-darker-gray;
    font-size: 2rem;
}


.file-upload {
    margin-top: $vertical-margin;
}

.uploader {
    &__max-size {
        margin-top: $vertical-margin;
    }
}

.queue-list {
    margin-top: $vertical-margin;

    &__item {
        margin-top: $vertical-margin;
        &--hidden {
            display: none;
        }
    }
}

.upload-field {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &__input {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        z-index: 10;
    }

    &__icon {
        position: absolute;
        font-size: 2rem;
        z-index: 1;
        cursor: pointer;
    }

    &__label {
        padding-left: pxToRem(30);
        cursor: pointer;
    }
}
