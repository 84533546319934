.readonly-field {
    &__label {
        font-size: $font-size-small;
        color: $color-text-gray;
    }

    &__data {
        font-size: $font-size-form;
    }
}
