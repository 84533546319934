.notifications-list {
    &__item {
        cursor: pointer;

        &--no-pointer {
            cursor: default;
        }

        &:first-child .notification {
            border-top: 1px solid $color-light-gray;
        }
    }
}

.notification {

    padding: $gutter-width $gutter-width*2  $gutter-width  $gutter-width;
    border-bottom: 1px solid $color-light-gray;
    position: relative;

    &--unread {
        background-color: $color-light-blue;

        &::before {
            content: "\2219";
            position: absolute;
            line-height: pxToRem(20);
            right: $gutter-width;
            font-size: pxToRem(50);
            color: $color-primary-blue;
        }
    }

    &__title {
        line-height: $font-size-text * 1.5;
        font-weight: bold;
    }
}

.notification-btn {
    height: pxToRem(50);
    width: pxToRem(50);
    background-image: url('../../icons/notification-outline.svg');
    background-repeat: no-repeat;
    background-size: pxToRem(26);
    background-position: center;

    @-moz-document url-prefix() {
        background-position-y: initial;
        background-position-x: center;
    }

    &--new {
        background-image: url('../../icons/notification.svg');
    }
}
