@use "sass:math";

.userlist-actions {
    margin: math.div($vertical-margin, 2) 0 0;
    display: none;
    justify-content: flex-end;

    @media screen and (min-width: $screen-md-min) {
        display: flex;
    }

    &__btn {
        margin-right: pxToRem(3);
        margin-left: 2rem;
    }
}

.userlist-export {
    &__text {
        margin-left: math.div($gutter-width, 2);
    }

    &__icon {
        position: relative;
        top: pxToRem(2);
    }
}

.userlist-import {
    &__label {
        display: inline-block;
        color: $color-darker !important;
        padding-left: pxToRem(5);

        &--nopadding {
            padding: 0;
        }
    }
}

.userimport-info {
    margin: $vertical-margin 0 0;
    &__users {
        color: $color-form-error;
    }

    &__roles {
        color: $color-text-warning;
    }
}

.import-info {
    &.isHidden {
        display: none;
    }
}

.premium {
    &__element {
        display: none;
    }

    > .premium__element {
        display: block;
    }
}

.activation-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__button {
        font-size: 2rem;
    }
}
