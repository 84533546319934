.subscription-info {
    &__premium-ad {
        border: 1px solid $color-input-border;
        border-radius: 5px;
        padding: $input-padding;
        min-height: pxToRem(48);
        @media screen and (min-width: $screen-md-min) {
            line-height: pxToRem(48);
        }
        width: auto;
        display: inline-flex;
        align-items: center;
    }

    &__premium-ad-star {
        display: flex;
        margin-right: $gutter-width;
        font-size: 1.6rem;
        margin-top: -2px;
    }
}

.premium-date {
    font-family: 'TheSans-B6SemiBold';
}

.profile-row--last {
    margin-bottom: $vertical-margin;
}

.profile-title {
    margin-top: 1.5*$vertical-margin;
}
