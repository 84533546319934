@use "sass:math";

.relationship-onboarding {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    top: -$header-height;
    padding-top: 2 * $header-height;
    height: 100vh;

    @media screen and (min-width: $screen-sm-min) {
         padding-top: 3 * $header-height;
    }

    &__main-block {
        flex-grow: 1;
    }

    &__text-block {
        text-align: center;
        margin-bottom: math.div($margin-vertical, 2)
    }

    &__title-image {
        display: block;
        margin-inline: auto;

        &--bottom-margin {
            margin-bottom: $vertical-margin;
        }
    }

    &__title {
        @include emph-title;
    }

    &__done-lead {
        margin-bottom: $vertical-margin;
    }

    &__add-button {
        margin-bottom: math.div($vertical-margin, 2)
    }

    &__footer {
        flex-shrink: 0;
        margin-bottom: 5rem;
        text-align: center;
        font-size: $font-size-text;
    }

    .select__placehoder {
        color: $color-text-gray;
    }
}
