// $children: "h1" "h2" "h3" "h4" "h5" "h6" "div";

.header {
    margin: 0;
    height: $header-height;
    background-color: $color-dark-blue;
    color: $color-white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;

    &--klassenbuch {
        position: static;
        width: 100%;

        @media screen and (min-width: $screen-md-min) {
            position: fixed;
            left: 0;
            right: 0;
        }
    }

    &.is-open {
        position: fixed;
        left: 0;
        right: 0;
    }

    &__wrapper {
        position: relative;
    }

    &__btn {
        height: $header-height;
        line-height: $header-height;
        // width: $header-height;
        position: absolute;
        top: 0;
        font-size: pxToRem(30);
        color: $color-white;

        &--left {
            left: 0;
            padding-left: pxToRem(10);
            margin-top: pxToRem(4);
        }

        &--right {
            right: 0;
            border-left: pxToRem(1) solid $color-white-transparent;
            text-align: center;
        }
    }

    &__main {
        line-height: $header-height;
        height: $header-height;
        display: flex;
        justify-content: space-between;
        border-left: pxToRem(1) solid $color-white-transparent;
        margin-left: $header-height;
    }

    &__title {
        line-height: $header-height;
        display: inline-block;
        vertical-align: top;
    }

    &__options {
        display: inline-block;
        vertical-align: top;
        margin-right: $header-height;
        padding-right: $gutter-width;
        line-height: $header-height;
        height: $header-height;
    }

    &__context-button {

        font-size: 3rem;
        line-height: $header-height;
        float: right;

        &--hide {
            display: none;
        }

        @media screen and (min-width: $screen-md-min) {
            display: none;

            &--show {
                display: block;
                float: right;
            }
        }
    }

    &__title {
        padding-left: $gutter-width;
        overflow: hidden;
        height: $header-height;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__back {
        font-size: 2.5rem;
        margin-right: $gutter-width;
        float: left;
        line-height: $header-height;
        display: inline-block;
        color: $color-white;

        &--is-hidden {
            display: none;
        }
    }

    @media screen and (min-width: $screen-md-min) {

        .header__btn--left, .header__main, .header-title {
            transition: margin-left .25s ease-in-out;
        }

        &.is-open {
            .header__btn--left {
                width: $nav-width;
                text-align: left;
            }

            .header__main {
                margin-left: $nav-width;
            }

            .header__title {
                margin-left: 0;
            }

        }
    }


}

.main-title__heading {
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    background-image: url("../icons/helloclass-font-white.svg");
    background-repeat: no-repeat;
    height: $heading-height;
}
