.modal-menu {
    position: fixed;
    top: $header-height;
    padding-right: 0;

    &--show-mobile {
        display: block !important;
        z-index: 17;
    }

    &--hide {
        display: none !important;
    }

    &--no-top {
        top: 0;
    }

    @media screen and (min-width: $screen-md-min) {
        position: static;

        &--absolute {
            position: absolute;
        }
    }
}
