/* landing */

.app-landing-container {
    min-height: 100vh;
}

.login__reset-link--app  a {
    color: $color-greyish-blue;
}

/* footer */
#app .footer-wrapper {
    display: none;
}


