.login {
    &__form {
        // input:last-of-type {
        //     margin-top: $gutter-width;
        // }

        &__button {
            margin-top: $gutter-width;
            &.isHidden {
                display: none;
            }
        }
    }

    &__reset-link {
        margin-top: $gutter-width;
        font-size: $font-size-small;
        text-align: left;
        & a {
            color: $color-dark-blue !important;
        }
    }

    &__separator {
        //https://dev.to/codyhouse/css-nugget-text-divider-in-flexbox-3310
        display: flex;
        align-items: center;
        margin-block: $gutter-width;
        color: $color-text-gray;

        &::before, &::after {
          content: '';
          height: 1px;
          background-color: $color-text-gray;
          flex-grow: 1;
        }

        &::before {
          margin-right: pxToRem(10);
        }

        &::after {
          margin-left: pxToRem(10);
        }
    }

}

.login-form {
    &__button {
        margin-top: $gutter-width;
        &.isHidden {
            display: none;
        }
    }
}

.login-spinner {
    display: flex;
    justify-content: center;
}

.edulog-login {
    display: block;
    text-align: center;
    padding-block: pxToRem(4);
    color: $color-dark-blue;
    border: 1px $color-dark-blue solid;
    border-radius: $small-radius;

    &__icon {
        margin-left: pxToRem(5);
        height: pxToRem(16);
    }
}
