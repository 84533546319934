.dropdown__item {
    display: block;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.unstyled-label {
    color: inherit;
    font-size: inherit;
    display: inherit;
    max-width: inherit;
    margin-bottom: inherit;
    font-weight: inherit;
    cursor: pointer;
}
