.overlay-list {

    @media screen and (min-width: $screen-md-min) {
        min-width: pxToRem(200);
        width: auto;
        margin: 0;
    }

    &__item {
        height: pxToRem(50) !important;
        padding: 0 pxToRem(5) $gutter-width;
        border-bottom: $color-light-gray 1px solid;

        @media screen and (min-width: $screen-md-min) {
            padding-top: pxToRem(5)
        }
    }
}


.overlay-dropdown {

    @media screen and (min-width: $screen-md-min) {
        min-width: pxToRem(200);
    }

    &__menu {
        @media screen and (min-width: $screen-md-min) {
            position: absolute;
            background-color: transparent;
            top: inherit;
            left: inherit;
            right: inherit;
            bottom: inherit;
        }
    }

}
