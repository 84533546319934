.absence-user-display {
    &__field {
        margin-bottom: pxToRem(22);
        display: block;

        &:first-of-type {
            margin-top: pxToRem(22);

            @media screen and (min-width: $screen-md-min) {
                margin-top: pxToRem(66);
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
