.user-absences {
    &__summary {
        margin-bottom: $vertical-margin;
    }
}

.user-summary {

    &__summary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: pxToRem(20);
    }
}

.summary-element {
    &__title {
        font-size: $font-size-small;
        color: $color-text-gray;
    }

    &__lessons {
        font-family: 'TheSans-B6SemiBold', Helvetica, sans-serif;
    }
}

.absence-select {
    z-index: 15 !important;
}
