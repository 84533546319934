@use "sass:math";

.main {
  display: inline-block;
  position: relative;
  //background-color: rgba(222, 222, 222, 1);
  width: 100%;
  padding-left: 0;
  transition: .25s ease-in-out;
  /* todo: get plugin for provider prefixes */

  @media screen and (min-width: $screen-md-min) {
      padding-left: $nav-width;
    }

  &.is-full-width {
  @media screen and (min-width: $screen-md-min) {
      padding-left: 0;
    }
  }

  &__container {
    margin-top: $header-height;
    z-index: 10;

    &--klassenbuch {
      margin-top: 0;

      @media screen and (min-width: $screen-md-min) {
        margin-top: 2*$header-height;
      }
    }

    &--event-form {
        margin-top: 0;
        padding-top: math.div($vertical-margin, 2);
    }

    &--extra-top-margin {
        margin-top: $header-height + $vertical-margin;
    }
  };
}

.content-overlay {
  display: none;
  position: fixed;
  top: $header-height;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  &--is-shown {
    display: block;
    background-color: $color-black-transparent;

    @media screen and (min-width: $screen-md-min) {
      display: none;
    }
  }
}
