@use "sass:math";

.public-header {
    height: $new-heading-height;
}

.public-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (min-width: $screen-md-min) {
        max-width: $max-desktop-width;
        margin: 0 auto;
    }

    &__left {
        padding-left: $gutter-width;
    }
    &__right {
        padding-right: $gutter-width;
    }
}

.public-header-title {
    display: inline-block;
    &__heading {
        margin: $header-title-margin 0 $header-title-margin;
        height: $new-heading-title-height;
        margin-bottom: $vertical-margin;
        @media screen and (min-width: $screen-sm-min) {
            margin-bottom: $gutter-width;
        }
    }
}

.public-header-products {
    display: none;
    @media screen and (min-width: $screen-md-min) {
        display: inline-block;
    }

    &__link {
        color: white;
        margin-left: 4*$gutter-width;
        font-family: 'TheSans-B6SemiBold';
        &:hover {
            color: $color-primary-blue;
        }
    }
}

.public-header-menu {

    &__mobile {
        @media screen and (min-width: $screen-md-min) {
            display: none;
        }
    }

    &__desktop {
        display: none;
        @media screen and (min-width: $screen-md-min) {
            display: block;
        }
    }

    &__btns {
        margin: 0;
        line-height: $new-heading-height;

        & > li {
            margin-left: $gutter-width;
        }
    }

    &__dropdown-button {
        margin: math.div($new-heading-height, 4) 0;
        color: $color-white;
        font-size: math.div($new-heading-height, 2);
    }
}


// todo: move to buttons.scss

.btn--outline {
    color: $color-primary-blue;
    border: pxToRem(1) solid;
    background-color: transparent;

    &-inversehover:hover {
        color: $color-white;
        background-color: $color-primary-blue;
    }

    &:hover {
        color: $color-white;
    }
}


