.admin-item {
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &__info {
        width: 80%
    }

    &__title {
        margin-top: 0;
    }

    &__button {
        margin-top: 10px;
    }

    &__actions {
        vertical-align: middle;
        text-align: right;
        @media screen and (min-width: $screen-md-min) {
            padding-right: $gutter-width;
        }
    }

    &__checkbox {
        position: relative;
        top: 3px;
    }

    &__edulog {
        height: pxToRem(13);
        padding-left: pxToRem(5);
    }
}

.admin-list {
    &__item {
        border-bottom: 1px solid $color-light-gray;
        padding: $gutter-width 0;
    }

    &__item:first-child {
        // padding-top: 0;
        border-top: 1px solid $color-light-gray;
    }
}


.user-list {
    & > .members-list {
        margin-bottom: 0;
    }

    &--margin-top > .row {
        margin-top: $vertical-margin;
    }
}

.user-list--bottom-margin > .members-list {
    margin-bottom: $admin-list-height-mobile;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: $admin-list-height;
    }
}
