@use "sass:math";

.attachment--no-top-margin {
    .attachment {
        margin-top: 0;
    }
}

.item-filter {
    &__input {
        @media screen and (max-width: $screen-md-min) {
            // width: 100%;
            // float: none;
            margin-top: $vertical-margin;
        }
    }

    &__secondary {

        button {
            padding: 6px 12px;
            color: $color-primary-blue;
        }

        @media screen and (min-width: $screen-md-min) {
            display: none;
        }
    }
}

.attachment-bottom-padding {
    padding-bottom: $vertical-margin;
}

.admin-item__description {
    display: inline;
}

.context-checkbox {
    margin-right: pxToRem(3);
}

.ol--no-padding {
    padding-left: pxToRem(15);
}

// label settings
.label__input {
    color: $color-white;
    border: 0;
    width: auto;
    border-radius: $pill-radius;

    &::-webkit-input-placeholder { /* Chrome */
        color: $color-light-gray
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: $color-light-gray;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $color-light-gray;
        opacity: 1;
    }

    &:-moz-placeholder { /* Firefox 4 - 18 */
        color: $color-light-gray;
        opacity: 1;
    }
}

// round button for label
.btn-label--round {
    border-top-right-radius: pxToRem(15) !important;
    border-bottom-right-radius: pxToRem(15) !important;
}

// plus button
.btn-plus {
    $plus-btn-height: pxToRem(20);
    line-height: $plus-btn-height;
    height: $vertical-margin;

    &__icon, &__text {
        vertical-align: middle;
        line-height: $plus-btn-height;
        height: $plus-btn-height;
        display: inline-block;
    }

    &__text {
        margin-left: $gutter-width;
    }
}

.dropdown-menu--left-align {
    min-width: 100%;
}

.dropdown-menu--fix-top {
    top: 30px !important;
}

.dropdown-menu--left-align > li > button {
    text-align: left;
}

@media screen and (min-width: $screen-md-min) {
    .splitbutton {
        width: auto;

        &__action {
            width: auto;

        }

        &__caret {
            width: auto;
        }
    }
}

.splitbutton {
    width: 100%;

    &__action {
        width: 90%;

    }

    &__caret {
        width: 10%;
    }
}

// group-info in group-list
.group-info {
    &__title {
        margin-top: 0;
    }
}

.imprint {
    &__contact {
        white-space: pre;
    }
}

// make refresh icon rotate

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.glyphicon-refresh--rotate {
    animation: rotating 1.5s linear infinite;
}

.glyphicon-remove--red {
    color: $color-saffron;
}

// bottom margin for event form
.event-form {
    margin-bottom: $vertical-margin;
}

// demo button on landing
.btn--block-xxs {
    @media screen and (max-width: 350px) {
        margin-left: 0;
        margin-top: $vertical-margin;
    }
}

.registry-call2action__btn {
    @media screen and (max-width: 350px) {
        display: block;
    }
}

// ie 11 does not like svg (https://stackoverflow.com/questions/20541306/how-to-write-a-css-hack-for-ie-11)
@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .main-title__heading {
        background-image: url("../icons/helloclass-font-white.png");
        background-repeat: no-repeat
    }
    /* IE11 */
}

// notifications
.notification-page {
    .notification {
        margin-left: -$gutter-width;
        margin-right: -$gutter-width;

        @media screen and (min-width: $screen-md-min) {
            margin: 0;
        }
    }
}

.sidebar .notification-list__no-msg,
.sidebar .notification-list__error {
    margin-left: $gutter-width;
}

.calendar-wrapper {
    &--sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 16;

        & > .calendar {
            margin: 0
        }
    }

    &--margin {
        & > .calendar {
            margin-top: $header-height;
        }
    }

    @media screen and (min-width: $screen-md-min) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 16;
        padding-left: $gutter-width;
        transition: $navigation-animation-duration ease-in-out;

        & > .calendar {
            margin-top: $header-height;
        }

        &--margin {
            left: $nav-width;
        }
    }
}

// weekday button
.weekday-button {
    @media screen and (max-width: 375px) {
        max-width: pxToRem(50);
    }
}


//new public header hover on dropdown
.public-header-menu .dropdown-item:hover {
    background-color: transparent;
}


//not correct order for sdx rules, fix here
.messages > .message {
    max-height: 50px;
    margin-top: 6px;
    opacity: 1;
    color: #d12;
}

.message > i {
    margin-right: pxToRem(5);
}

bs-datepicker-container {
    left: 90px !important;
}

.users-modal .dropdown-menu {
    position: static;
}

.sdx-label {
    font-size: pxToRem(14) !important;
    color: rgb(102, 102, 102) !important;
    margin-bottom: pxToRem(5);
    line-height: pxToRem(24);
    font-weight: 700;
}

.select--last {
    margin-bottom: pxToRem(16) !important;
}

// button in bootstrap dropdown
.btn--dropdown {
    width: 100%;
    text-align: left;
}

// force sdx bottom margin
.hc-form-part--force {
    margin-bottom: $vertical-margin !important;
}

// landing page
.main-title {
    @media screen and (min-width: $screen-md-min) {
        font-size: pxToRem(54);
        line-height: pxToRem(54);
    }
}

// readonly input field
.input-field input[readonly] {
    background-color: $color-lighter-gray !important;
    border: solid 1px $color-darker-gray !important;
    color: $color-darker !important;
}

// signup radio
.signup-radio {
    margin-top: pxToRem(5) !important;

    @media screen and (min-width: $screen-xs-min) {
        margin-right: $gutter-width;
    }

    & label {
        color: $color-darker !important;
        font-size: $font-size-text !important;
    }
}

.language-selection {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $screen-xs-min) {
        flex-direction: row;
    }
}

.language-label {
    color: $color-darker !important;
}

.edulog-selection {
    margin-top: 1.5*$vertical-margin;
    margin-bottom: math.div($vertical-margin, 2);

    &__logo {
        height: 20px
    }
}

// bootstrap
.input-group-btn.open.show {
    display: table-cell !important;
}

.btn-group.state-dropdown.dropdown.open.show {
    display: inline-block !important;
}

