.hint {
    position: relative;
    background: $color-primary-blue;
    color: $color-white;
    border-radius: $widget-radius;
    padding: $gutter-width;
    min-height: pxToRem(100);
    max-width: pxToRem(300);
    margin: auto !important;

    @media screen and (min-width: $screen-md-min) {
        margin-left: $gutter-width;
    }

    &__close-btn {
        position: absolute;
        right: $gutter-width;
        top: $gutter-width;
        cursor: pointer;
    }

    &--bubble {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-top-color: $color-primary-blue;
            border-bottom: 0;
            margin-left: -20px;
            margin-bottom: -20px;
        }
    }
}

.hint-header {
    &__title {
        margin-top: 0;
    }
}
