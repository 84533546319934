@use "sass:math";

.comments {
    padding: 0 0 $vertical-margin;
}

.comments-form {
    background-color: $color-lighter-gray;
    padding: 0 $gutter-width math.div($vertical-margin, 2);

    &__button {
        display: block;
    }
}

.comment {
    border-top: 1px solid $color-light-gray;
    padding-top: $vertical-margin;
    margin-bottom: $vertical-margin;

    &:last-of-type {
        border-bottom: 1px solid $color-light-gray;
        padding-bottom: $vertical-margin;
    }
}

.comment-upload {
    margin-top: $vertical-margin;

    & a {
        color: $color-darker;
    }
}

