@use "sass:math";

.role {

    border: 1px solid $color-light-gray;
    border-radius: pxToRem(6);
    margin-bottom: $vertical-margin;

    &__header {
        padding: math.div($gutter-width, 2) $gutter-width;
        background-color: $color-lighter-gray;
        border-top-left-radius: pxToRem(6);
        border-top-right-radius: pxToRem(6);
        display: flex;
        justify-content: space-between;
    }

    &__remove{
        line-height: pxToRem(18);
        margin-top: pxToRem(10);
        font-size: pxToRem(18);
    }

    &__title {
        display: inline
    }

    &__permission {
        margin: $gutter-width 0 0 $gutter-width;

        &--hidden {
            display: none;
        }
    }
}

.role-error__icon {
    margin-right: math.div($gutter-width, 2);
}

.permission-label {
    font-size: $font-size-text;

    & > * {
        line-height: pxToRem(15);
    }

    &__text {
        color: $color-darker;
    }

    &--disabled {
        cursor: not-allowed;
    }
}

.permission_checkbox {

    margin-right: pxToRem(5);

    &--dark {
        color: $color-darker;
    }

}
