.label-dropdown {

    &__button {
        border: 1px $color-white solid;
        height: $pill-height;
        line-height: $pill-height;
        min-width: 2*$gutter-width;
        border-radius: $pill-radius;
        padding: 0 $gutter-width;
        display: inline-block;
        color: $color-white;

        &:hover, &:focus, &:active {
            border: 1px $color-white solid;
            color: $color-white;
        }
    }
    &.open > .dropdown-toggle.btn-primary {
        border: 1px $color-white solid;

        &:hover, :focus {
            border: 1px $color-white solid;
        }
    }

    &__icon {
        line-height: $pill-height;
        margin-left: $gutter-width;
        color: $color-white;
        vertical-align: top;
    }
}

.label-pill {
    height: $pill-height;
    line-height: $pill-height;
    color: $color-white;
    display: inline-block;
    min-width: 2*$gutter-width;
    border-radius: $pill-radius;

    &__text {
        margin: 0 $gutter-width;
    }
}
