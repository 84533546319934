.main-navigation-wrapper {
  width: 100%;
  position: fixed;
  top: $header-height;
  left: 0;
  bottom: 0;
  right: -100%;
  left: -100%;
  z-index: 17;
  background-color: $color-lighter-blue;
  color: $color-white;
  transition: $navigation-animation-duration ease-in-out;
  overflow-y: scroll;
  /* todo: get plugin for provider prefixes */

  @media screen and (min-width: $screen-md-min) {
    padding-top: $header-height;
    top: 0;
    overflow-y: auto;
  }

  // padding-top: $header-height;

  &.is-shown {
    right: 100%;
    left: 0;

    @media screen and (min-width: $screen-md-min) {
      width: $nav-width;
      left: 0;
      right: inherit;
    }
  }
}

.main-nav {
  margin-top: $header-height;
}

.main-navigation__item {
  border-bottom: 1px solid $color-greyish-blue;

  & p {
    font-size: $font-size-h3;
  }

  &--subtle {
    color: $color-greyish-blue;

    & p {
      font-size: $font-size-text;
    }
  }

  &--title, &--user-panel {
    padding: $vertical-margin $gutter-width $gutter-width $gutter-width;
  }

  a {
    display: inline-block;
    padding: $gutter-width $gutter-width;
    color: $color-white;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
  }

  &--active p a {
    color: $color-primary-blue;
  }
}

.user__groups {
  &:after {
    content: ", ";
  }

  &--last {
    &:after {
      content: "";
    }
  }
}
