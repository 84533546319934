@use "sass:math";

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.no-top-margin {
    margin-top: 0;
}

.no-bottom-margin {
    margin-bottom: 0;
}

.vertical-margin {
    margin-top: $vertical-margin;

    &--sm-plus {
        @include desktop {
            margin-top: 2 * $vertical-margin;
        }
    }
}

.gutter-mt {
    margin-top: $gutter-width;
}

.vertical-margin-bottom {
    margin-bottom: $vertical-margin;
}

.vertical-margin-bottom\/2 {
    margin-bottom: math.div($vertical-margin, 2);
}

.unstyled-list {
    list-style: none;
    padding: 0;
}

.icon-plus {
    font-size: 3rem;

    &--small {
        font-size: 2rem;
    }
}

.inline-block {
    display: inline-block;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.full-width {
    width: 100%;
}

.no-scroll {
    overflow: hidden;
}

.wizard-image-box {

    padding: $vertical-margin 0 pxToRem(150+30);
    background-repeat: no-repeat;
    background-size: pxToRem(150);
    background-position: 95% 105%;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;

    & p {
        margin: 0;
    }
}

.center {
    display: flex;
    justify-content: center;
}

// blue box

.action-box {
    border-top: 2px solid darken($color-blue-sky, 10);
    border-bottom: 3px solid $color-darker;
    background-color: $color-blue-sky;
}

//

.show-only-\@s {
    display: block;
    @media screen and (min-width: $screen-sm-min) {
        display: none;
    }
}

.hidden-\@ml {
    display: block;
    @media screen and (min-width: $screen-sm-min) {
        display: none;
    }
}

.hidden-\@l {
    display: block;
    @media screen and (min-width: $screen-md-min) {
        display: none;
    }
}

.hidden-\@s {
    display: none;
    @media screen and (min-width: $screen-md-min) {
        display: block;
    }
}

.hidden-\@xs {
    display: none;
    @media screen and (min-width: $screen-sm-min) {
        display: block;
    }
}

.isHidden {
    display: none !important;
}

.bg-dark-blue {
    background-color: $color-dark-blue !important;
}

.bg-honey {
    background-color: $color-honey !important;
}

.bg-purple {
    background-color: $color-purple !important;
}

.bg-saffron {
    background-color: $color-saffron !important;
}

.bg-caribbean {
    background-color: $color-caribbean !important;
}

.bg-teal {
    background-color: $color-teal !important;
}

.bg-rose {
    background-color: $color-rose !important;
}

.bg-jade {
    background-color: $color-jade !important;
}

.bg-orange {
    background-color: $color-orange !important;
}


// fade out text
.fade-out {
    position: relative;
    overflow-x: hidden;
}

.fade-out__gradient {
    position: absolute;
    top: 0;
    right: 0;
    width: pxToRem(20);
    bottom: 1px;
    z-index: 15;

    background: linear-gradient(to right, transparentize($color: $color-white, $amount: 1) () 0%, $color-white);

    &--blue {
        background: linear-gradient(to right, transparentize($color: $color-lighter-blue, $amount: 1) () 0%, $color-lighter-blue);
    }

    &--bottom {
        background: linear-gradient(to bottom, transparentize($color: $color-white, $amount: 1) () 0%, $color-white);
        bottom: 0;
        top: inherit;
        height: pxToRem(20);
        right: 0;
        width: auto;
    }

    &--margin-left {
        left: $gutter-width;
    }

    //   &:hover {
    //     background: $color-white-transparent
    //   }

}

.highlight-link {

    color: $color-primary-blue;

    &:hover {
        color: $color-primary-blue;
    }
}

.doted-line--top {
    background-image: linear-gradient(to right, $color-white 30%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 10px 3px;
    background-repeat: repeat-x;
}

.doted-line--bottom {
    background-image: linear-gradient(to right, $color-white 30%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 3px;
    background-repeat: repeat-x;
}

// overwrites

.col-md-2--wide {
    @media screen and (min-width: $screen-md-min) {
        width: 20%;
    }
}


.unstyled-link {
    text-decoration: none;
    color: inherit;

    &:hover, &:focus {
        text-decoration: none;
        color: inherit;
    }
}

.widget-separator {

    margin-top: $vertical-margin;
    position: relative;

    &__text {

        width: 20%;
        margin: 0 auto;
        display: block;
        text-align: center;

        @media screen and (min-width: $screen-md-min) {
            width: 30%;
        }

        &:before {
            content: '';
            border-bottom: 1px solid;
            width: 40%;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: $gutter-width;

            @media screen and (min-width: $screen-md-min) {
                width: 35%;
            }
        }

        &:after {
            content: '';
            border-bottom: 1px solid;
            width: 40%;
            display: inline-block;
            position: absolute;
            top: 50%;
            right: $gutter-width;

            @media screen and (min-width: $screen-md-min) {
                width: 35%;
            }
        }
    }
}

.error-list {
    color: $color-saffron;
}

// used in profile
.info-box {
    border: 3px solid $color-primary-blue;
    border-radius: 5px;

    &__text {
        font-size: $font-size-h3;
        margin: math.div($vertical-margin, 2) $gutter-width;
        color: $color-primary-blue;
    }
}


.header .dropdown-menu {
    color: $color-darker;
    position: static;
}

.dropdown-menu li {
    padding-left: $gutter-width;
    height: $dropdown-li-height;
    line-height: $dropdown-li-height;
}

.dropdown-menu--no-heigth li {
    height: auto;

}

a {
    color: $color-primary-blue;

    &.a--no-highlight {
        color: $color-darker;
    }

    &:hover {
        text-decoration: none;
    }
}

.dropdown {
    &__icon {
        font-size: pxToRem(18);
        font-weight: bold;
        display: inline-block;

        &--inverted {
            transform: rotate(180deg);
        }
    }
}

label {
    color: $color-text-gray !important;
    font-size: $font-size-small;
}

.font-text-gray {
    color: $color-text-gray;
}

.container, .container-fluid--limit {
    max-width: 992px;
}


.document-thumbnail {
    font-size: 6em;
    color: $color-text-gray;
    display: block;
    padding: 5px;
    &--small {
        font-size: 2em;
    }
}


.link {
    &--secondary {
        color: $color-primary-blue;
        position: relative;
        padding-right: 5px;

        &::after {
            content: '>';
            position: absolute;
            right: 0;
            font-size: 0.8rem;
            height: 1.25rem;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    &--tertiary {
        color: $color-text-gray;
    }
}

.userdata-box {
    font-size: $font-size-h3;
    border-radius: pxToRem(4);
    padding-left: pxToRem(20);
    line-height: pxToRem(48);
    background-color: $color-lighter-gray;
    border: solid 1px $color-darker-gray;
    word-break: break-all;
}

.accesscode-btn {
    margin: $vertical-margin 0;
    color: $color-primary-blue;
}

.hc-form-part {
    margin-bottom: $vertical-margin;
}

.hc-header-offset {
    margin-top: $header-height;
}

.hc-form-header-offset {
    margin-top: pxToRem(68);
}

