.classbook {

    &.isHidden {
        display: none;
    }

    @media screen and (min-width: $screen-md-min) {
      padding: 0 $gutter-width $vertical-margin;
    }
}

.klassenbuch-spinner {
    margin-top: $vertical-margin;
}

.weekday {

    @media screen and (min-width: $screen-md-min) {
      margin: 0;
    }

    &:hover {
      cursor: pointer;
    }

  &__heading {
    // padding: 0 $gutter-width;
    // margin: 3rem 0 1rem;
    border-bottom: 1px solid $color-lighter-gray;
    // height: $classbook-header-height;

    @media screen and (min-width: $screen-md-min) {
        // height: 2em;
        border: 0;
        margin: 0;
        padding: 0;
      }
  }

  &__title-wrapper {
    @media screen and (min-width: $screen-md-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__title {
    margin: $vertical-margin 0 $vertical-margin 0;
    @media screen and (min-width: $screen-md-min) {
      margin: $gutter-width 0 $gutter-width 0;
    }
  }

  &__add-wrapper {
    text-align: right;
    margin: $vertical-margin 0 $vertical-margin 0;
    @media screen and (min-width: $screen-md-min) {
        padding: 0;
        margin: $gutter-width 0 $gutter-width 0;
      }
  }

  &__add-event {
    font-size: 2rem;
    //display: inline-block;
    line-height: $classbook-header-height;
    // float: right;
    // margin-right: $gutter-width;

    @media screen and (min-width: $screen-md-min) {
        // margin-right: 0;
        // margin-left: $gutter-width;
        // float: none;
        display: inline-block;
      }
  }

  &:last-child {

    .dayslots {

    @media screen and (min-width: $screen-md-min) {
        border-right: 1px solid $color-light-gray;
      }

    }
  }

}

.dayslots {

  padding: 0 $gutter-width;
  // clear: both;

  @media screen and (min-width: $screen-md-min) {
      padding: 0;
      height: $numSlots*$classbook-cell-height;
      position: relative;
      border-top: 1px solid $color-light-gray;
      border-left: 1px solid $color-light-gray;
      // overflow: hidden;
      float: none;
    }

    &__grid {
      @media screen and (min-width: $screen-md-min) {
        height: $classbook-cell-height;
        border-bottom: 1px solid $color-light-gray;
      }

      &:hover {
        background-color: $color-almost-white;
      }
    }
}

.timeslot {

  &__heading {
    @media screen and (min-width: $screen-md-min) {
      height: $classbook-timeslog-top-margin;
    }
  }

  &__row {
    @media screen and (min-width: $screen-md-min) {
      height: $classbook-cell-height;
      line-height: $classbook-cell-height;
      text-align: right;
    }
  }
}

.bottom-actions {

    &.isHidden {
        display: none;
    }

    &__btn {
        display: block;
        margin: $gutter-width auto 0;
    }

    @media screen and (min-width: $screen-md-min) {
        display: none;
    }
}

.onboarding-hint {
    position: fixed;
    top: 20%;
    left: 15%;
    right: 15%;
    z-index: 10;

    @media screen and (min-width: $screen-md-min) {
        position: absolute;
        top: pxToRem(300);
    }
}
