$desktop-width: 1024px;

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin grey-shadow {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
}

@mixin emph-text {
    font-family: 'TheSans-B6SemiBold', Helvetica, sans-serif;
}

@mixin emph-title {
    font-size: $font-size-form;
    line-height: $font-size-lead;
    @include emph-text;
}

@mixin heading {
    font-family: 'TheSansB5', sans-serif;
    font-weight: 700;
    margin-block: pxToRem(10);
    color: $color-black !important;
}
