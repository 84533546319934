$padding-vertical: 8vh;
$margin-vertical: 6vh;

.instruction-wrapper {
    padding-top: $padding-vertical;
    padding-bottom: $padding-vertical;
    position: relative;
    text-align: center;

    .letter {
        width: 124px;
        margin: 0 auto;
    }

    p {
        margin-bottom: 0px;
    }

    .no-email-received {
        margin-top: $margin-vertical;

    }
}

.verify-page {
    &__back-button {
        margin-top: $margin-vertical;
        display: inline-block;
    }
}

