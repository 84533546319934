@use "sass:math";

$slots-length: 0 1 2 3 4 5 6 7 8 9;
$slots-start: 0 1 2 3 4 5 6 7 8;
$numSlots: 9;

// fonts
$font-size-text: pxToRem(15);
$font-size-small: pxToRem(13);
$font-size-big: pxToRem(36);
$font-size-h2: pxToRem(27);
$font-size-h3: pxToRem(18);
$font-size-lead: pxToRem(21);
$font-size-form: pxToRem(18);
$font-line-height: pxToRem(1.35);

// new sizes
$font-size-heading1: pxToRem(30);
$font-size-heading2: pxToRem(24);
$font-size-heading3: $font-size-form;


$font-size-form-error: pxToRem(14);

// header
$header-height: pxToRem(50);

// gutters
$gutter-width: pxToRem(15);

$vertical-margin: 2*$gutter-width;

// navigation
$nav-width-small: pxToRem(45);
$nav-width: pxToRem(200);
$navigation-animation-duration: .25s;

// sidebar
$sidebar-width: pxToRem(300);

// widget
$widget-radius: pxToRem(12);

// classbook
$classbook-cell-height: pxToRem(140) !default;
$classbook-cell-margin: 5px !default;
$classbook-cell-margin-rem: pxToRem(5);

$classbook-header-height: pxToRem(25);
$classbook-top-margin: pxToRem(25);
$classbook-timeslog-top-margin: $classbook-header-height + $classbook-top-margin;

$mobile-margin: pxToRem(15);

$btn-big-height: pxToRem(38);
$btn-massive-height: pxToRem(56);

// bootstrap elements
$btn-side-padding: pxToRem(12);
$btn-top-padding: pxToRem(6);

// forms
$input-height: pxToRem(34);
$pill-height: pxToRem(30);
$pill-radius: pxToRem(15);

$cancel-button-size: pxToRem(20);

// admin-list
$admin-list-height-mobile: pxToRem(100);
$admin-list-height: pxToRem(60);
$admin-list-height-big-mobile: pxToRem(135);

// public header
$heading-height: 3*$gutter-width;
// new public header
$new-heading-height: 4*$gutter-width;
$new-heading-title-height: math.div($new-heading-height, 2);
$header-title-margin: math.div($new-heading-height - $new-heading-title-height, 2);

// public pages
$max-desktop-width: 90vw;

// dropdown height
$dropdown-li-height: pxToRem(40);

// Extra small screen / phone
$screen-xs-min: 480px !default;

// Small screen / tablet
$screen-sm-min: 768px !default;

// Medium screen / desktop
$screen-md-min: 992px !default;

// Large screen / wide desktop
$screen-lg-min: 1200px !default;

// Large scree / wider desktop
$screen-lg-med: 1260px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: $screen-sm-min - 1 !default;
$screen-sm-max: $screen-md-min - 1 !default;
$screen-md-max: $screen-lg-min - 1 !default;

$input-padding: 0 16px;

// radius
$small-radius: 4px;
