.lead-in {
    &__heading {
        text-align: center;
        margin-bottom: 0;
        font-family: 'TheSans-B7Bold', sans-serif;
        &.lead {
            font-size: $font-size-lead;
        }

        @include desktop {
            text-align: left;

            &--centered {
                text-align: center;
            }

        }
    }

    &__subheading {
        text-align: center;

        &--small {
            font-size: $font-size-text !important;
            margin-top: 1rem;
        }

        @include desktop {
            text-align: left;

            &--centered {
                text-align: center;
            }
        }
    }
}
