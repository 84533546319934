.form--action {
    margin-top: 2*$gutter-width;
}

.form-actions {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: $gutter-width 0;
    background-color: $color-lighter-gray;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    color: $color-white;
    z-index: 20;

    &__item {
        display: inline-block;
    }

    &__cancel {
        font-size: $cancel-button-size
    }
}

.state-dropdown {

    color: $color-darker;

    &__button {
        margin: 0 $gutter-width !important;
        & > span {
            padding: 0 $gutter-width;
        }
    }
}

.form {
    &__input_error {
        color: $color-form-error;
        font-size: $font-size-form-error
    }
}

.form-section {
    border-top: 1px $color-light-gray solid;
    padding-top: $gutter-width;

    &--no-border{
        border-top: none;
    }

    &--no-padding {
        padding-top: 0;
    }
}
