.landing-title {

    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.22);

    &__container {
        margin-bottom: 1.5*$vertical-margin;
    }

    &__title {
        @media screen and (min-width: $screen-md-min) {
            margin-top: $vertical-margin;
        }
    }
}

.registry-call2action {

    padding-bottom: $vertical-margin;
    padding-top: $gutter-width;
    margin-bottom: 1.5*$vertical-margin;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 10px;
        background-image: linear-gradient(to right, $color-white 30%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 10px 3px;
        background-repeat: repeat-x;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 10px;
        background-image: linear-gradient(to right, $color-white 30%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 10px 3px;
        background-repeat: repeat-x;
    }

    &__btn--outline {
        background-color: transparent !important;
        border: 1px solid $color-primary-blue;
        color: $color-primary-blue;
        margin-left: $gutter-width;

        &:hover {
            color: $color-primary-blue;
        }
    }

    @media screen and (min-width: $screen-md-min) {
        margin-top: $vertical-margin;
    }
}

.landing-action {
    background-color: $color-wall-yellow;
    padding-bottom: $vertical-margin;
    max-height: pxToRem(700);

    @media screen and (min-width: $screen-md-min) {
        background: url('../../images/helloclass-hero-new.jpg') no-repeat top right;
        background-size: cover;
        padding-top: $vertical-margin;
        padding-bottom: pxToRem(235);
    }
}

.landing-old-login {
    &__link {
        margin-top: $gutter-width;
    }
}

.landing-features {
    background-color: $color-white;

    @media screen and (min-width: $screen-md-min) {
        padding-top: 2*$vertical-margin;
    }

    &__call2action {
        text-align: center;
        margin-top: $vertical-margin;
    }

    &__title {
        text-align: center;
    }

}
