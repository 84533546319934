.absence-dateselector {
    height: pxToRem(50);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.semester-selector {
    display: flex;

    &__left-button {
        margin-right: pxToRem(10);
    }

    &__right-button {
        margin-left: pxToRem(10);
    }
}

.unit-selector {
    &__dropdown {
        & .state-dropdown__button {
            display: flex;
        }

        & .dropdown__icon {
            font-weight: normal;
            font-size: pxToRem(14);
            line-height: 1.428;
        }
    }
}
