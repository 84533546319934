@font-face {
  font-family: 'helloclass-icons';
  src:  url('../fonts/helloclass-icons.eot?8vpdye');
  src:  url('../fonts/helloclass-icons.eot?8vpdye#iefix') format('embedded-opentype'),
    url('../fonts/helloclass-icons.woff2?8vpdye') format('woff2'),
    url('../fonts/helloclass-icons.ttf?8vpdye') format('truetype'),
    url('../fonts/helloclass-icons.woff?8vpdye') format('woff'),
    url('../fonts/helloclass-icons.svg?8vpdye#helloclass-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change ../fonts */
  font-family: 'helloclass-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-3-dots:before {
  content: "\e900";
}
.icon-3-lines:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-bin:before {
  content: "\e904";
}
.icon-clock:before {
  content: "\e905";
}
.icon-cross:before {
  content: "\e906";
}
.icon-document:before {
  content: "\e907";
}
.icon-pen:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e909";
}
.icon-speechbubble:before {
  content: "\e90a";
}
.icon-user:before {
  content: "\e90b";
}
.icon-arrow-down:before {
  content: "\e90c";
}
.icon-magnifier:before {
  content: "\e90d";
}
.icon-calendar:before {
  content: "\e90e";
}
.icon-remove:before {
  content: "\e90f";
}
.icon-clip:before {
  content: "\e910";
}
.icon-box:before {
  content: "\e911";
}
.icon-check-box:before {
  content: "\e912";
}
.icon-circle:before {
  content: "\e913";
}
.icon-check-circle:before {
  content: "\e914";
}
.icon-link:before {
  content: "\e915";
}
.icon-download:before {
  content: "\e916";
}
.icon-open:before {
  content: "\e917";
}
.icon-upload:before {
  content: "\e918";
}
.icon-lock:before {
  content: "\e919";
}
.icon-remove-user:before {
  content: "\e91a";
}
.icon-reset:before {
  content: "\e91b";
}
