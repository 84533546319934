.sm-full-selector {
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    inset: 0 0 0 0 !important;
    background-color: $color-black-transparent;
    z-index: 20;

    @media screen and (min-width: $screen-md-min) {
        width: 100%;
        position: inherit;
        background-color: transparent;
    }

}

.sm-full-selector-list {

    background-color: $color-white;
    margin: pxToRem(60) auto;
    width: 80%;
    border-radius: pxToRem(5);

    &__item {
        @media screen and (max-width: $screen-md-min) {
            height: pxToRem(50) !important;
            line-height: pxToRem(50) !important;
            padding: pxToRem(5) $gutter-width;
            border-bottom: $color-light-gray 1px solid;
        }
    }
}
