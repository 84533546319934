$max-width: 600px;
$font-weight-bold: 600;
$feature-width: 90vw;
$margin-vertical: 10vh;

.premium-page {

    &__content {
        max-width: $max-width;
    }

    &__price {
        font-size: pxToRem(20);
        color: $color-primary-blue;
        font-weight: $font-weight-bold;
    }

    &__feature-list {
        list-style: none;
        width: $feature-width;
        max-width: 100%;
        padding: 0;
        margin: 0 auto;
    }

    &__feature-list-item {
        padding: 17px 0;
        padding-left: 45px;
        background-image: url('../icons/tick-green.svg');
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: center;
        background-size: 29px;
        border-top: 1px solid $color-light-gray;

        &:last-child {
            border-bottom: 1px solid $color-light-gray;
        }
    }

    &__feature-list-item-title {
        font-weight: $font-weight-bold;

    }

}

.buy-btn {
    &__content {
        display: flex;
        justify-content: space-between;
    }

}
