// scree specific
@media (max-width: $screen-sm-min) {
  .hide-ifsmallerthan-mobile-large {
    display: none;
  }
}

@media (max-width: $screen-md-min) {
  .hide-ifsmallerthan-tablet {
    display: none;
  }
}

@media (max-width: $screen-xs-min) {
  /* 480px */
  .hide-ifsmallerthan-mobile {
    display: none;
  }
}

@media (min-width: $screen-md-min) {
  .hide-ifbiggerthan-tablet {
    display: none;
  }
}